import Properties from '../properties.js';
export const API = {
  GETCATEGORY: `${Properties.BASE_PATH}category`,
  GETDELIVERY: `${Properties.BASE_PATH}delivery`,
  GETCOURSEBYCAT: `${Properties.BASE_PATH}coursebycategory`,
  GETCOURSEDETAILS: `${Properties.BASE_PATH}course`,
  CARTDETAILS: `${Properties.BASE_PATH}cart`,
  GETCMS: `${Properties.BASE_PATH}cms`,
  DELIVERYMODES: `${Properties.BASE_PATH}deliverymodes`,
  PAYLATER: `${Properties.BASE_PATH}paylater`,
  OUTLINE: `${Properties.BASE_PATH}outline`,
  CONTACT: `${Properties.BASE_PATH}contact`,
  CARTBEFOREPAY: `${Properties.BASE_PATH}cartbeforepay`,
};