import React,{useState,useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import {
    Nav,
    Navbar,
    NavDropdown,
    Col,
    Row,
    Container,
    Dropdown,
  } from "react-bootstrap";
  import { Link } from "react-router-dom";  
  import Properties from '../properties.js';

const CategoryMegamenu = (props) => {

return (
    <>
    {/* {console.log("courseList===>",props.courseList)} */}
        
        <div className="CoursesNav">
          
            {props.courseList.map((item) => (
            <div className="navcol">                
                <Dropdown.Header><img src={item.details.cat_image} /><p><Link to={Properties.BASE_URL+item.details.cat_slug}>{item.details.cat_name}</Link><hr style={{color:"01fcb7",opacity:1}}/></p></Dropdown.Header>
                <div className="menubox">  
                {item.details.course?.length && item.details.course.map((course,index) => (
            course.name != null && 
                <Dropdown.Item>
                <Link to={Properties.BASE_URL+item.details.cat_slug+"/"+course.course_slug}>
                    <a className="nav-link" role="button">
                    {course.name}
                    </a>
                </Link>
                </Dropdown.Item>
                ))}
                </div>
                {/* <Dropdown.Divider /> */}
            
            </div>
                ))}
         
         </div>
    </>
);
}
export default CategoryMegamenu;