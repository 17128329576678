
import React, { useEffect, useState, useMemo } from 'react';
import Header from './Header';
import Footer from './Footer';
import CartUserInfo from './CartUserInfo';
import Checkout from './Checkout';
import { getCartData } from '../services/Api';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link,useNavigate, useHistory } from "react-router-dom";
import Properties from '../properties.js';
const Cart = (props) => {
  const [getcart, setCartData] = useState([]);
  const cartData = JSON.parse(localStorage.getItem('cart'));
  const cartDetailsDt = JSON.parse(localStorage.getItem('cartDetails'));
  console.log("props",props)
  const [alertShow, setAlertShow] = useState(false);
  const [alertBtnval, setalertBtnval] = useState();
  const [fromSec, setfromSec] = useState((props?.pg=="userinfo")?true:false);
  const [cartSec, setcartSec] = useState((props?.pg=="cart")?true:false);
  const [paymentSec, setPymentSec] = useState((props?.pg=="checkout")?true:false);
  const [getCurrencyWisePrice, setCurrencyWisePrice] = useState();  
  const navigate = useNavigate();
  //const history = useHistory();
  
  const getCartDatafun = async () => {
    if(cartData?.length){
      await getCartData(cartData).then(cartres => {
        //console.log("here");
        if (cartres.status === 200) {
          console.log("cartres",cartres.data);
          const cartDataWithQuantity = cartres.data.map(item => ({ ...item, quantity:  cartDetailsDt?.find(dtlsitem => dtlsitem.course_name === item.course_name)?.quantity || 1 }));
          //console.log("cartDataWithQuantity===>",cartDataWithQuantity);
          cartDataWithQuantity.sort(customSort);
          localStorage.setItem('cartDetails', JSON.stringify(cartDataWithQuantity));// new added
          setCartData(cartDataWithQuantity);
        }
      })
    }
  };
  function customSort(a, b) {
      return cartData.indexOf(a.course_venue_id) - cartData.indexOf(b.course_venue_id);
  }

  useEffect(() => {
    getCartDatafun();
  }, [cartData?.length]);

  const handleQuantityChange = (event, index) => {
    setalertBtnval(index);
    if(event.target.value > 0){
    const newCart = [...getcart];
    newCart[index].quantity = event.target.value;
    localStorage.setItem('cartDetails', JSON.stringify(newCart));
    setCartData(newCart);
    }else{
      setAlertShow(true);
    }
  };

  const calculateTotalPrice = () => {
    const currency = localStorage.getItem('currency');
    let curr;
    if (currency === "pound") {
      curr = "£ ";
    } else if (currency === "dollar") {
      curr = "$ ";
    } else {
      curr = "€ ";
    }
    return curr + getcart.reduce((total, item) => {
      let price;
      if (currency === "pound") {
        price = item.pound_price;
      } else if (currency === "dollar") {
        price = item.dollar_price;
      } else {
        price = item.euro_price;
      }
      return total + (parseFloat(price) * parseInt(item.quantity));
    }, 0);
  };
  const cartRemove = (event) => {
    let targetval = event.target.value;
    console.log("getCart",getcart);
    const newCart = [...getcart];
    newCart.splice(targetval, 1);
    setCartData(newCart);
    localStorage.setItem('cartDetails', JSON.stringify(newCart));
    const cartIds = JSON.parse(localStorage.getItem('cart'));
    cartIds.splice(targetval, 1);
    localStorage.setItem('cart', JSON.stringify(cartIds)); // Store updated cart in local storage
    setAlertShow(false);
  }
  const userFormSec = () => {
    //navigate('/cart/userinfo'); 
    //window.history.pushState({}, '', '/cart/userinfo');
    //setfromSec(true);
    //setcartSec(false);
    window.location.href='/cart/userinfo';
  }
  const currencyPrice = (pricedt) => {
    const currency = localStorage.getItem('currency');
    //console.log("pricedt===>",pricedt)
    switch (currency) {
        case 'pound':
            return "£ "+(parseFloat(pricedt.pound_price * pricedt.quantity)).toLocaleString();
        case 'doller':
          return "$ "+(parseFloat(pricedt.dollar_price * pricedt.quantity)).toLocaleString();
        default:
          return "€ "+(parseFloat(pricedt.euro_price * pricedt.quantity)).toLocaleString();
    }
};
  return (
    <>
      {props.categoryList?.length > 0 && <Header cartData={getcart} clsName={"inner-header"} mataTitle="Cart" metaDes="Cart" categoryList={props.categoryList} />}
      <div className="inner_banner_wrapper clr" style={{ background: 'url("'+Properties.IMAGE_PATH+'public/gallery/Banner_Cookie_Policy.png")' }}>
        <h1>Cart</h1>
      </div>
      <section className="cart_page cart_page_act">
        <div className="container">
          <ul className="breadcrumb">
            <li className={cartSec ? 'actves' : '' }><a><span>1.</span> Shopping Cart</a></li>
            <li className={fromSec ? 'actves' : '' }><a>2. Your Details</a></li>
            <li className={paymentSec ? 'actves' : '' }><a>3. Payment</a></li>
          </ul>
        </div>
        <div className={(fromSec)?'usercheckform':'checkoutwrp'}>
          <div className="container" style={{ display: cartSec ? 'block' : 'none' }}>
            <div className="cartnew_lft cart_page_dtls dsktps ordernn">
              <div>
                <table width="100%" border={0} cellSpacing={0} cellPadding={0} className="tb_border">
                  <tbody>
                    {getcart?.length > 0 && getcart.map((item, index) => ( 
                      <tr key={index}>
                        <td align="left" valign="top" style={{ paddingRight: '30px' }}><i>{item.course_name}</i>
                          <div className="date_venunc"><span className="venf"><img src={item.flag_image || ''} width={24} alt={item.venue_name || ''} className="flag" />{item.venue_name || ''}</span>
                            <span className="dattte">{item.start_date}</span>
                          </div>
                        </td>
                        <td align="left" valign="top">
                          <div className="number">
                            <input type="number" value={item.quantity} onChange={(event) => handleQuantityChange(event, index)} />
                          </div>
                        </td>
                        <td align="left" valign="top">
                          <p className><span className="">{currencyPrice(item)}</span></p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="cartnew_rgt checknergt bask" style={{ position: 'relative' }}>
              <h2><strong>Summary</strong> </h2>
              <p className="ttledel cart_addoff">
              </p><div className="imm_tott"><span style={{ fontWeight: 'bold' }}>Total</span> <strong className="gtotal">{calculateTotalPrice().toLocaleString()}</strong></div>
              <p />
              <div className="pay_gty positiobt">
                {getcart?.length > 0 ? (
                  <div className="butt_nort basketsucure">
                    <Link onClick={userFormSec} className="y_btn">Next</Link>
                    {/* <Link to="userinfo" className="y_btn">Next</Link> */}
                  </div>
                ) : null}
                
                <div className="newcartcard"><img src="https://kogitodemo.com/hostedsites/ltfe/theme_v_1/images/cart_cards.png" /> </div>

                <div className="clr" />
              </div>
            </div>
          </div>
          <CartUserInfo show={fromSec} price={calculateTotalPrice()} />
          <Checkout show={paymentSec} price={calculateTotalPrice()} />
        </div>
        
      </section>
      <Modal
        show={alertShow}
        backdrop="static"
        keyboard={false}
        animation={false}
      >
        <Modal.Header>
          <Modal.Title>Are you sure you want to remove this course?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="secondary" value={alertBtnval} onClick={cartRemove} >
            Yes
          </Button>
          <Button variant="secondary" onClick={()=>{setAlertShow(false)}}>
            No
          </Button>
        </Modal.Body>        
      </Modal>               
      <Footer />      
    </>
  )
}

export default Cart;