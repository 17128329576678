import React, { useState, useEffect } from "react";
import { Modal,Button } from 'react-bootstrap';

const BusinessConsultation = (props) => {
  const [activeTab, setActiveTab] = useState('tab1');
  const [activeTabmob, setActiveTabmob] = useState('tab1');
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const handleMouseLeave = () => {
    setActiveTab('tab1');
  };
  const tabStyle = {
    padding: '10px 20px',
    cursor: 'pointer',
    transition: 'opacity 0.3s, zIndex 0.3s',
  };
  const activeTabStyle = {
    ...tabStyle,
    opacity: 1,
    zIndex: 5,
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleTabClickMobile = (tab) => {
    setActiveTabmob(tab);
    setIsModalOpen(true);
  };
  const handlePopupClose = () => {
    // setActiveTabmob(tab);
    setIsModalOpen(false);
  };

  return <>
    <div className="contaiaer"  dangerouslySetInnerHTML={{ __html: props?.cmsData.business_consultation }}>

      
    </div>
    <div className="contaiaer">

    

    <div className="secop">
    <div className="hedd"  dangerouslySetInnerHTML={{ __html: props?.cmsData?.our_approach }}>
    
    </div>
          <div className="row">
          <div className="col-md-6">
            <ul className="indexes" onMouseLeave={handleMouseLeave}>
                  <li  onMouseEnter={() => handleTabClick('tab3')} onMouseLeave={handleMouseLeave} data-index={0} className="green" style={{"border-color":"transparent"}}>P3</li>
                  <li  onMouseEnter={() => handleTabClick('tab2')} onMouseLeave={handleMouseLeave}  data-index={1} className="red" style={{}}>P2</li>
                  <li  onMouseEnter={() => handleTabClick('tab1')} onMouseLeave={handleMouseLeave}  data-index={2} className="blue" style={{"border-color":"transparent"}}>P1</li>
                  <li  onMouseEnter={() => handleTabClick('tab4')} onMouseLeave={handleMouseLeave}  data-index={3} className="yellow" style={{"border-color":"transparent"}}>P4</li>
            </ul>
            </div>
            <div className="col-md-6">
            <ul class='tabs'>
              <li class={activeTab === 'tab1' ? "tab active" : 'tab'} style={activeTab === 'tab1' ? activeTabStyle : tabStyle}>
                <article class={activeTab === 'tab1' ? "tab-content active" : 'tab'} dangerouslySetInnerHTML={{ __html: props?.cmsData.phase_one }}>
                  
                </article>

              </li>
              <li class={activeTab === 'tab2' ? "tab active" : 'tab'} style={activeTab === 'tab2' ? activeTabStyle : tabStyle}>
                <article class={activeTab === 'tab2' ? "tab-content active" : 'tab'}  dangerouslySetInnerHTML={{ __html: props?.cmsData.phase_two }}>
                
                </article>
              
              </li>
              <li class={activeTab === 'tab3' ? "tab active" : 'tab'} style={activeTab === 'tab3' ? activeTabStyle : tabStyle}>
                <article class={activeTab === 'tab3' ? "tab-content active" : 'tab'}  dangerouslySetInnerHTML={{ __html: props?.cmsData.phase_three }}>             
                  
                </article>            
              </li>
              <li class={activeTab === 'tab4' ? "tab active" : 'tab'} style={activeTab === 'tab4' ? activeTabStyle : tabStyle}>
                <article class={activeTab === 'tab4' ? "tab-content active" : 'tab'}  dangerouslySetInnerHTML={{ __html: props?.cmsData.phase_four }}>
                  
                </article>
          
              </li>
            </ul>
          </div>
      </div>
    </div>

    <div className="nop">
    <div className="secopg">
    <div className="hedd" dangerouslySetInnerHTML={{ __html: props?.cmsData?.our_approach }}>
    </div>
      <div className="container">
          <div className="row">
            <ul className="indexes" >
                  <li  onClick={() => handleTabClickMobile('tab3')} data-index={0} className="green" style={{"border-color":"transparent"}}> P3</li>
                  <li  onClick={() => handleTabClickMobile('tab2')} data-index={1} className="red" style={{}}>P2</li>
                  <li  onClick={() => handleTabClickMobile('tab1')} data-index={2} className="blue" style={{"border-color":"transparent"}}>P1</li>
                  <li  onClick={() => handleTabClickMobile('tab4')} data-index={3} className="yellow" style={{"border-color":"transparent"}}>P4</li>
            </ul>
            <ul class='tabs'>
            <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)} onHide={()=>handlePopupClose()} keyboard={false}
            animation={false}
            dialogClassName="modal-90w">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <li class={activeTabmob === 'tab1' ? "tab active" : 'tab'} style={activeTabmob === 'tab1' ? activeTabStyle : tabStyle}>
                  <article class={activeTabmob === 'tab1' ? "tab-content active" : 'tab'}  dangerouslySetInnerHTML={{ __html: props?.cmsData.phase_one }}>
                    
                  </article>

                </li>
                <li class={activeTabmob === 'tab2' ? "tab active" : 'tab'} style={activeTabmob === 'tab2' ? activeTabStyle : tabStyle}>
                  <article class={activeTabmob === 'tab2' ? "tab-content active" : 'tab'}  dangerouslySetInnerHTML={{ __html: props?.cmsData.phase_two }}>
                  
                  </article>
                
                </li>
                <li class={activeTabmob === 'tab3' ? "tab active" : 'tab'} style={activeTabmob === 'tab3' ? activeTabStyle : tabStyle}>
                  <article class={activeTabmob === 'tab3' ? "tab-content active" : 'tab'}  dangerouslySetInnerHTML={{ __html: props?.cmsData.phase_three }}>                   
                    
                  </article>            
                </li>
                <li class={activeTabmob === 'tab4' ? "tab active" : 'tab'} style={activeTabmob === 'tab4' ? activeTabStyle : tabStyle}>
                  <article class={activeTabmob === 'tab4' ? "tab-content active" : 'tab'}  dangerouslySetInnerHTML={{ __html: props?.cmsData.phase_four }}>
                    
                  </article>
            
                </li>
              </Modal.Body>
              <Modal.Footer>           
              
              </Modal.Footer>
            </Modal>
              
            </ul>
          </div>
      </div>
    </div>
    </div>

    

    </div>
    <div className="conclu">
        <div className="container" dangerouslySetInnerHTML={{ __html: props?.cmsData.conclusion }}>
        
        </div>
        <div className="container">       

        <a class="cart_btn2" onClick={(event) => props.infomationreq(event,'onsite-instructor-led-training')}> Information Request</a>
        </div>
    </div>
  
    



  </>;
};

export default BusinessConsultation;
