
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { CartBeforeePayPOST } from '../services/Api';


const CartUserInfo = (props) => {
//   const [getcart, setCartData] = useState([]);
//   const cartData = JSON.parse(localStorage.getItem('cart'));
//   const [alertShow, setAlertShow] = useState(false);
//   const [alertBtnval, setalertBtnval] = useState();

const [validated, setValidated] = useState(false);
const [formData, setFormData] = useState({
  full_name: '',
  company_name: '',
  email: '',
  phone_no: '',
  country: '',
  streetline1: '',
  streetline2:'',
  zip: '',
  city: '',
  });
const handleSubmit = async (event) => {
  const form = event.currentTarget;
  event.preventDefault();
  if (form.checkValidity() === false) {    
    event.stopPropagation();
  }else{
    console.log(formData);
    localStorage.setItem('userInfo',JSON.stringify(formData));
    await CartBeforeePayPOST(formData).then(data => {
      //console.log(data);
      if (data.status === 200) {
        window.location.href='/cart/checkout';
      }
    })
  }
  setValidated(true);
};

const handleInputChange = (event) => {
  const { name, value } = event.target;
  setFormData({ ...formData, [name]: value });
};
  
    
  

  
  
  return (
    <>   
      <div className="container">
        
        <Form noValidate validated={validated}  style={{ display: props.show ? 'block' : 'none' }} onSubmit={handleSubmit}>
          <div className='row'>
            <div className="col-md-8 ">
              <Row className="mb-3 mbformrow">
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label></Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="full_name"
                    placeholder="FULL NAME*"
                    value={formData.full_name}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback  type="invalid">Enter Full Name</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom02">
                <Form.Label></Form.Label>
                  <Form.Control
                    name="company_name"
                    type="text"
                    placeholder="COMPANY NAME"
                    value={formData.company_name}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                  <Form.Label></Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="EMAIL*"
                      aria-describedby="inputGroupPrepend"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter email.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                  <Form.Label></Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      name="phone_no"
                      placeholder="PHONE NO*"
                      aria-describedby="inputGroupPrepend"
                      value={formData.phone_no}
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter phone no.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="mb-3 mbformrow">
              <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                  <Form.Label></Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      name="streetline1"
                      placeholder="Street line 1*"
                      value={formData.streetline1}
                      onChange={handleInputChange}
                      aria-describedby="inputGroupPrepend"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter billing address.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                  <Form.Label></Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      name="streetline2"
                      placeholder="Street line 2"
                      aria-describedby="inputGroupPrepend" 
                      value={formData.streetline2}
                      onChange={handleInputChange}                 
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label></Form.Label>
                  <Form.Control type="text" name="city" placeholder="City*" value={formData.city} onChange={handleInputChange} required />
                  <Form.Control.Feedback type="invalid">
                    Please enter city.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom05">
                  <Form.Label></Form.Label>
                  <Form.Control type="text" name="zip" value={formData.zip} onChange={handleInputChange} placeholder="Zip" />              
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom05">
                  <Form.Label></Form.Label>
                  <Form.Select aria-label="Country*" name="country" value={formData.country} onChange={handleInputChange} required>
                    <option value="">Select Country</option>
                    <option value="225">United Kingdom</option>
                    <option value="1">AFGHANISTAN</option>
                    <option value="2">ALBANIA</option>
                    <option value="3">ALGERIA</option>
                    <option value="4">AMERICAN SAMOA</option>
                    <option value="5">ANDORRA</option>
                    <option value="6">ANGOLA</option>
                    <option value="7">ANGUILLA</option>
                    <option value="8">ANTARCTICA</option>
                    <option value="9">ANTIGUA AND BARBUDA</option>
                    <option value="10">ARGENTINA</option>
                    <option value="11">ARMENIA</option>
                    <option value="12">ARUBA</option>
                    <option value="13">AUSTRALIA</option>
                    <option value="14">AUSTRIA</option>
                    <option value="15">AZERBAIJAN</option>
                    <option value="16">BAHAMAS</option>
                    <option value="17">BAHRAIN</option>
                    <option value="18">BANGLADESH</option>
                    <option value="19">BARBADOS</option>
                    <option value="20">BELARUS</option>
                    <option value="21">BELGIUM</option>
                    <option value="22">BELIZE</option>
                    <option value="23">BENIN</option>
                    <option value="24">BERMUDA</option>
                    <option value="25">BHUTAN</option>
                    <option value="26">BOLIVIA</option>
                    <option value="27">BOSNIA AND HERZEGOVINA</option>
                    <option value="28">BOTSWANA</option>
                    <option value="29">BOUVET ISLAND</option>
                    <option value="30">BRAZIL</option>
                    <option value="31">BRITISH INDIAN OCEAN TERRITORY</option>
                    <option value="32">BRUNEI DARUSSALAM</option>
                    <option value="33">BULGARIA</option>
                    <option value="34">BURKINA FASO</option>
                    <option value="35">BURUNDI</option>
                    <option value="36">CAMBODIA</option>
                    <option value="37">CAMEROON</option>
                    <option value="38">CANADA</option>
                    <option value="39">CAPE VERDE</option>
                    <option value="40">CAYMAN ISLANDS</option>
                    <option value="41">CENTRAL AFRICAN REPUBLIC</option>
                    <option value="42">CHAD</option>
                    <option value="43">CHILE</option>
                    <option value="44">CHINA</option>
                    <option value="45">CHRISTMAS ISLAND</option>
                    <option value="46">COCOS (KEELING) ISLANDS</option>
                    <option value="47">COLOMBIA</option>
                    <option value="48">COMOROS</option>
                    <option value="49">CONGO</option>
                    <option value="50">CONGO, THE DEMOCRATIC REPUBLIC OF THE</option>
                    <option value="51">COOK ISLANDS</option>
                    <option value="52">COSTA RICA</option>
                    <option value="53">COTE D'IVOIRE</option>
                    <option value="54">CROATIA</option>
                    <option value="55">CUBA</option>
                    <option value="56">CYPRUS</option>
                    <option value="57">CZECH REPUBLIC</option>
                    <option value="58">DENMARK</option>
                    <option value="59">DJIBOUTI</option>
                    <option value="60">DOMINICA</option>
                    <option value="61">DOMINICAN REPUBLIC</option>
                    <option value="62">ECUADOR</option>
                    <option value="63">EGYPT</option>
                    <option value="64">EL SALVADOR</option>
                    <option value="65">EQUATORIAL GUINEA</option>
                    <option value="66">ERITREA</option>
                    <option value="67">ESTONIA</option>
                    <option value="68">ETHIOPIA</option>
                    <option value="69">FALKLAND ISLANDS (MALVINAS)</option>
                    <option value="70">FAROE ISLANDS</option>
                    <option value="71">FIJI</option>
                    <option value="72">FINLAND</option>
                    <option value="73">FRANCE</option>
                    <option value="74">FRENCH GUIANA</option>
                    <option value="75">FRENCH POLYNESIA</option>
                    <option value="76">FRENCH SOUTHERN TERRITORIES</option>
                    <option value="77">GABON</option>
                    <option value="78">GAMBIA</option>
                    <option value="79">GEORGIA</option>
                    <option value="80">GERMANY</option>
                    <option value="81">GHANA</option>
                    <option value="82">GIBRALTAR</option>
                    <option value="83">GREECE</option>
                    <option value="84">GREENLAND</option>
                    <option value="85">GRENADA</option>
                    <option value="86">GUADELOUPE</option>
                    <option value="87">GUAM</option>
                    <option value="88">GUATEMALA</option>
                    <option value="89">GUINEA</option>
                    <option value="90">GUINEA-BISSAU</option>
                    <option value="91">GUYANA</option>
                    <option value="92">HAITI</option>
                    <option value="93">HEARD ISLAND AND MCDONALD ISLANDS</option>
                    <option value="94">HOLY SEE (VATICAN CITY STATE)</option>
                    <option value="95">HONDURAS</option>
                    <option value="96">HONG KONG</option>
                    <option value="97">HUNGARY</option>
                    <option value="98">ICELAND</option>
                    <option value="99">INDIA</option>
                    <option value="100">INDONESIA</option>
                    <option value="101">IRAN, ISLAMIC REPUBLIC OF</option>
                    <option value="102">IRAQ</option>
                    <option value="103">IRELAND</option>
                    <option value="104">ISRAEL</option>
                    <option value="105">ITALY</option>
                    <option value="106">JAMAICA</option>
                    <option value="107">JAPAN</option>
                    <option value="108">JORDAN</option>
                    <option value="109">KAZAKHSTAN</option>
                    <option value="110">KENYA</option>
                    <option value="111">KIRIBATI</option>
                    <option value="112">KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF</option>
                    <option value="113">KOREA, REPUBLIC OF</option>
                    <option value="114">KUWAIT</option>
                    <option value="115">KYRGYZSTAN</option>
                    <option value="116">LAO PEOPLE'S DEMOCRATIC REPUBLIC</option>
                    <option value="117">LATVIA</option>
                    <option value="118">LEBANON</option>
                    <option value="119">LESOTHO</option>
                    <option value="120">LIBERIA</option>
                    <option value="121">LIBYAN ARAB JAMAHIRIYA</option>
                    <option value="122">LIECHTENSTEIN</option>
                    <option value="123">LITHUANIA</option>
                    <option value="124">LUXEMBOURG</option>
                    <option value="125">MACAO</option>
                    <option value="126">MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF</option>
                    <option value="127">MADAGASCAR</option>
                    <option value="128">MALAWI</option>
                    <option value="129">MALAYSIA</option>
                    <option value="130">MALDIVES</option>
                    <option value="131">MALI</option>
                    <option value="132">MALTA</option>
                    <option value="133">MARSHALL ISLANDS</option>
                    <option value="134">MARTINIQUE</option>
                    <option value="135">MAURITANIA</option>
                    <option value="136">MAURITIUS</option>
                    <option value="137">MAYOTTE</option>
                    <option value="138">MEXICO</option>
                    <option value="139">MICRONESIA, FEDERATED STATES OF</option>
                    <option value="140">MOLDOVA, REPUBLIC OF</option>
                    <option value="141">MONACO</option>
                    <option value="142">MONGOLIA</option>
                    <option value="143">MONTSERRAT</option>
                    <option value="144">MOROCCO</option>
                    <option value="145">MOZAMBIQUE</option>
                    <option value="146">MYANMAR</option>
                    <option value="147">NAMIBIA</option>
                    <option value="148">NAURU</option>
                    <option value="149">NEPAL</option>
                    <option value="150">NETHERLANDS</option>
                    <option value="151">NETHERLANDS ANTILLES</option>
                    <option value="152">NEW CALEDONIA</option>
                    <option value="153">NEW ZEALAND</option>
                    <option value="154">NICARAGUA</option>
                    <option value="155">NIGER</option>
                    <option value="156">NIGERIA</option>
                    <option value="157">NIUE</option>
                    <option value="158">NORFOLK ISLAND</option>
                    <option value="159">NORTHERN MARIANA ISLANDS</option>
                    <option value="160">NORWAY</option>
                    <option value="161">OMAN</option>
                    <option value="162">PAKISTAN</option>
                    <option value="163">PALAU</option>
                    <option value="164">PALESTINIAN TERRITORY, OCCUPIED</option>
                    <option value="165">PANAMA</option>
                    <option value="166">PAPUA NEW GUINEA</option>
                    <option value="167">PARAGUAY</option>
                    <option value="168">PERU</option>
                    <option value="169">PHILIPPINES</option>
                    <option value="170">PITCAIRN</option>
                    <option value="171">POLAND</option>
                    <option value="172">PORTUGAL</option>
                    <option value="173">PUERTO RICO</option>
                    <option value="174">QATAR</option>
                    <option value="175">REUNION</option>
                    <option value="176">ROMANIA</option>
                    <option value="177">RUSSIAN FEDERATION</option>
                    <option value="178">RWANDA</option>
                    <option value="179">SAINT HELENA</option>
                    <option value="180">SAINT KITTS AND NEVIS</option>
                    <option value="181">SAINT LUCIA</option>
                    <option value="182">SAINT PIERRE AND MIQUELON</option>
                    <option value="183">SAINT VINCENT AND THE GRENADINES</option>
                    <option value="184">SAMOA</option>
                    <option value="185">SAN MARINO</option>
                    <option value="186">SAO TOME AND PRINCIPE</option>
                    <option value="187">SAUDI ARABIA</option>
                    <option value="188">SENEGAL</option>
                    <option value="189">SERBIA AND MONTENEGRO</option>
                    <option value="190">SEYCHELLES</option>
                    <option value="191">SIERRA LEONE</option>
                    <option value="192">SINGAPORE</option>
                    <option value="193">SLOVAKIA</option>
                    <option value="194">SLOVENIA</option>
                    <option value="195">SOLOMON ISLANDS</option>
                    <option value="196">SOMALIA</option>
                    <option value="197">SOUTH AFRICA</option>
                    <option value="198">SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS</option>
                    <option value="199">SPAIN</option>
                    <option value="200">SRI LANKA</option>
                    <option value="201">SUDAN</option>
                    <option value="202">SURINAME</option>
                    <option value="203">SVALBARD AND JAN MAYEN</option>
                    <option value="204">SWAZILAND</option>
                    <option value="205">SWEDEN</option>
                    <option value="206">SWITZERLAND</option>
                    <option value="207">SYRIAN ARAB REPUBLIC</option>
                    <option value="208">TAIWAN, PROVINCE OF CHINA</option>
                    <option value="209">TAJIKISTAN</option>
                    <option value="210">TANZANIA, UNITED REPUBLIC OF</option>
                    <option value="211">THAILAND</option>
                    <option value="212">TIMOR-LESTE</option>
                    <option value="213">TOGO</option>
                    <option value="214">TOKELAU</option>
                    <option value="215">TONGA</option>
                    <option value="216">TRINIDAD AND TOBAGO</option>
                    <option value="217">TUNISIA</option>
                    <option value="218">TURKEY</option>
                    <option value="219">TURKMENISTAN</option>
                    <option value="220">TURKS AND CAICOS ISLANDS</option>
                    <option value="221">TUVALU</option>
                    <option value="222">UGANDA</option>
                    <option value="223">UKRAINE</option>
                    <option value="224">UNITED ARAB EMIRATES</option>
                    <option value="225">UNITED KINGDOM</option>
                    <option value="226">UNITED STATES</option>
                    <option value="227">UNITED STATES MINOR OUTLYING ISLANDS</option>
                    <option value="228">URUGUAY</option>
                    <option value="229">UZBEKISTAN</option>
                    <option value="230">VANUATU</option>
                    <option value="231">VENEZUELA</option>
                    <option value="232">VIET NAM</option>
                    <option value="233">VIRGIN ISLANDS, BRITISH</option>
                    <option value="234">VIRGIN ISLANDS, U.S.</option>
                    <option value="235">WALLIS AND FUTUNA</option>
                    <option value="236">WESTERN SAHARA</option>
                    <option value="237">YEMEN</option>
                    <option value="238">ZAMBIA</option>
                    <option value="239">ZIMBABWE</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select a valid country.
                  </Form.Control.Feedback>            
                </Form.Group>
              </Row>
              <Row className="mb-3 mbformrow">
                <Form.Group className="mb-3">
                  <Form.Check
                    required
                    label="Agree to terms and conditions"
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                  />
                </Form.Group>
              </Row>
            </div>
              
            <div className="col-md-4 checknergt bask">
              <h2><strong>Summary</strong> </h2>
              <p className="ttledel cart_addoff">
              </p><div className="imm_tott"><span style={{ fontWeight: 'bold' }}>Total</span> <strong className="gtotal">{props.price}</strong></div>
              <p />
              <div className="pay_gty positiobt">
              <Button type="submit">Submit form</Button>                
                <div className="newcartcard"><img src="https://kogitodemo.com/hostedsites/ltfe/theme_v_1/images/cart_cards.png" /> </div>

                <div className="clr" />
              </div>
            </div> 
          </div>
        </Form>
      </div> 
         
           
    </>
  )
}

export default CartUserInfo;