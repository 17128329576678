import React, { useState } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';

const CustomSearchBox = ({ currentRefinement, refine, placeholder, handleSearch }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <input
      type="text"
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onKeyUp={handleSearch}
      placeholder={isFocused ? '' : placeholder}
      value={currentRefinement}
      onChange={(event) => refine(event.currentTarget.value)}
    />
  );
};
const ConnectedSearchBox = connectSearchBox(CustomSearchBox);
export default ConnectedSearchBox;
