 const properties = {
    BASE_PATH : 'https://www.aiinox.com/crm/api/',  // Live
    //BASE_PATH : 'https://www.kogitodemo.com/hostedsites/aiinoxcrm/api/',  //demo
    //IMAGE_PATH : 'https://kogitodemoadv.co.in/', //demo
    IMAGE_PATH : 'https://www.aiinox.com/', // Live
    //BACKEND_IMAGE_PATH : 'https://kogitodemo.com/hostedsites/aiinoxcrm/public/gallery/',  // demo
    BACKEND_IMAGE_PATH : 'https://www.aiinox.com/crm/public/gallery/', // Live
    BASE_URL : 'https://www.aiinox.com/'     // Live
    //BASE_URL : 'http://localhost:3000/'  // demo
}
export default properties;