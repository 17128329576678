import React,{useState,useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import DeliveryMode from './DeliveryMode';
import BusinessConsultation from './BusinessConsultation';
import {GetCms} from '../services/Api'
import Properties from '../properties.js';
import {GetDelivery,DeliveryPostData} from '../services/Api'

const Service = (props) => {
  const [deliveryData, setDeliveryMode] = useState([]);
    const [cmsData, setCmsMode] = useState([]); 
    const [pageData, setCmsPageData] = useState([]); 
    const [getTitle, setTitle] = useState([]); 
    const [getPageMetaDes, setPageMetaDes] = useState([]);
    const [deliveryState, setDeliverystate] = useState(false); 
   const [deliveryStateSlug, setDeliverystateSlug] = useState(false);
    const location = useLocation();
    const [formData, setFormData] = useState({
      fname: '',
      lname: '',
      email: '',
      delivery_mode: '',
      phone_no: '',
      message: '',
    });
   const [isSubmitting, setIsSubmitting] = useState(false);  
    const GetCmsData = async () => {
        await GetCms(props.slug).then(cmsresponse => {
            console.log("cmsresponse",cmsresponse);
           if (cmsresponse.status===200) {
            setCmsMode(cmsresponse.data);
            setCmsPageData(JSON.parse(cmsresponse.data?.page_details));       
           }
        })
    }
    const DeliveryClick = (event,slug) => {
      event.preventDefault();
      console.log("click delevery mode",slug); 
      setDeliverystate(true); 
      setDeliverystateSlug(slug);
    }
    const GetDeliveryData = async () => {
      await GetDelivery('all').then(deliveryresponse => {
         if (deliveryresponse.status===200) {
            //console.log(" Del Data",deliveryresponse.data)
            setDeliveryMode(deliveryresponse.data)         
         }
      })
    }
    const handlePopupClose = (slug) =>{
      //console.log("slug=====>",slug)
      setDeliverystate(false);
      setDeliverystateSlug(slug);
    }
    useEffect(() => {
        GetCmsData();
    }, [location,cmsData?.length]);
    useEffect(() => {
      GetDeliveryData(); 
  }, []);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle form submission
  const handleSubmit = async (event,slug,delivery_mode) => {
    event.preventDefault();
    setIsSubmitting(true);
    const updatedFormData = { ...formData, delivery_mode };
    setFormData(updatedFormData);
    await DeliveryPostData(updatedFormData).then(data => {
       if (data.status === 200) {
          setIsSubmitting(false);
          setDeliverystate(false);
          setDeliverystateSlug(slug);
       }
     })
    // You can send form data to an API, or perform any other action
  };
  return (<>
  {props.categoryList.length>0 && <Header clsName={"inner-header"}  mataTitle={pageData?.meta_title || ''} metaDes={pageData?.meta_desc || ''} categoryList={props.categoryList} />}
    <div className="inner_banner_wrapper clr dfd" id="cmsId" style={{ background: 'url("'+Properties.IMAGE_PATH+'public/gallery/MAIN%20IMAGE.png")' }}>
      
    <h1>{pageData?.banner_head}</h1>
    </div>
    <section className="py-5 iop">
  {/* <div classname="sub_headings">Classroom Training: Building a Strong Learning Foundation</div>

<div classname="sub_headings">&nbsp;</div>

<p>Our instructor-led classroom training programs provide a vibrant and interactive learning environment. Participants benefit from direct interaction with experienced trainers, fostering knowledge exchange and peer-to-peer learning. This collaborative approach encourages active participation and a deeper understanding of concepts. We offer many high-quality, standardised training programs covering diverse topics, from core business skills to the latest industry trends. These pre-designed courses are a great starting point, but we also understand the importance of customisation. Our team can work with you to tailor the curriculum to address your specific needs and ensure the training directly impacts your organisational goals. Additionally, classroom training is a cost-effective solution for upskilling larger groups of employees simultaneously.</p> */}

{/* <div classname="sub_headings">&nbsp;</div>

<div classname="sub_headings">In-Company Training: Flexibility &amp; Customisation</div>

<div classname="sub_headings">&nbsp;</div>

<p>In-house training fosters a sense of team spirit and collaboration as employees learn together. They can share their knowledge and experiences, building upon each other&#39;s strengths. This collaborative approach can lead to a deeper understanding of the material and increased engagement with the training content. Furthermore, in-house training allows for on-site application of newly acquired skills. Employees can immediately put their learning into practice in their daily work, reinforcing knowledge retention and accelerating the return on investment for your training program.</p>
<a className="cart_btn2 " style={{ width: '200px' }} onClick={(event) => DeliveryClick(event,'online-instructor-led-training')}> Information Request</a>
<div classname="sub_headings">&nbsp;</div> */}

{/* <div classname="sub_headings">Executive Training: Confidentiality &amp; Discretion</div>

<div classname="sub_headings">&nbsp;</div>

<p>Our executive training programs equip senior leaders with the critical skills to make strategic decisions, lead effectively, and drive organisational growth. These programs go beyond traditional training methods, offering group sessions and personalised coaching to cater to each executive&#39;s needs. Participants receive focused guidance and mentorship from experienced coaches, helping them develop essential leadership qualities such as strategic thinking, effective communication, and conflict resolution.</p>

<p>&nbsp;</p>

<p>We understand the sensitive nature of executive training. Our programs provide a discreet and confidential learning environment where executives can openly discuss challenges, share best practices, and forge stronger relationships with their peers in a supportive, judgment-free atmosphere.</p>
<a className="cart_btn2 " style={{ width: '200px' }} onClick={(event) => DeliveryClick(event,'online-self-paced-training')}> Information Request</a> */}

{/* <div classname="sub_headings">&nbsp;</div>

<div classname="sub_headings">Business Consultation: Long-Term Success &amp; Proactive Support</div>

<div classname="sub_headings">&nbsp;</div>

<p>Our commitment to your success extends beyond the initial consultation. We provide ongoing support throughout the implementation process, ensuring a smooth transition and maximising the return on investment for your business initiatives. Our consultants work closely with your team to address any questions or challenges that may arise, and we offer ongoing monitoring and evaluation to track progress and measure the effectiveness of the implemented solutions. Ultimately, we aim to be your long-term partner in achieving sustainable growth and exceeding your business objectives. This proactive approach ensures your organisation remains competitive and well-positioned for success in the ever-evolving business landscape.</p> */}
{/* <div className="container" dangerouslySetInnerHTML={{ __html: pageData.business_consultation }}>

                </div> */}
<div className="container">
                <BusinessConsultation infomationreq={DeliveryClick} cmsData={pageData} />
</div>
{/* <a className="cart_btn2 " style={{ width: '200px' }} onClick={(event) => DeliveryClick(event,'onsite-instructor-led-training')}> Information Request</a> */}
</section>
<Footer />
<DeliveryMode DeliveryData={deliveryData} DelSlug={deliveryStateSlug} handlePopupClose={handlePopupClose} formData={formData} isSubmitting={isSubmitting} handleSubmit={handleSubmit} handleInputChange={handleInputChange} DelState={deliveryState} />
</>
  )
}

export default Service