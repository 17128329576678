import React, { useState, useEffect } from 'react';
import { Modal,Button } from 'react-bootstrap';
import {DeliveryPostData} from '../services/Api';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
function PayLaterPop(props) {
    const deliveryData = props.DeliveryData;
     //console.log(props.DelState);
    const show = props.DelState; 
    
    const [validated, setValidated] = useState(false);
    const courseDlsss = {
        courseName: props?.courseName ??'',
        courseDate: props?.courseDate ?? '',
        courseVenue: props?.courseVenue ??''
    };
    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
    
      setValidated(true);
    };
    return(
        <>
        {/* {console.log(deliveryData)}  */}
        {deliveryData.map((item,index) => (        
            <Modal
            show={props.DelSlug === item.slug ? props.DelState : false}
            onHide={()=>props.handlePopupClose(item.slug)}
            keyboard={false}
            animation={false}
            dialogClassName="modal-90w"
          >
            <Modal.Header closeButton>
                <div className="mod-img">                    
                </div>
                <div className="mod-heading">
                {props?.courseName?.length && 
                     <>
                        <div className="small_heading">Course Delivery Modes</div>
                        <div className="heading">{item.delivery_mode}</div>                    
                        <div className="coursespop_heading"><span>Name :</span> {props?.courseName}</div>
                        <div className="coursespop_heading"><span>Date  :</span> {props?.courseDate}</div>
                        <div className="coursespop_heading"><span>Venue :</span> {props?.courseVenue}</div>
                        
                     </>
                  }
                </div>
            </Modal.Header>
            <Modal.Body>
            <Form noValidate validated={props.validated} onSubmit={(event) => props.handleSubmit(event,courseDlsss)}>
                <Row className="mb-3 mbformrow">
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Label></Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name="full_name"
                        placeholder="FULL NAME*"
                        value={props.formData.full_name}
                        onChange={props.handleInputChange} 
                    />
                    <Form.Control.Feedback  type="invalid">Enter Full Name</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                    <Form.Label></Form.Label>
                    <Form.Control
                        
                        type="text"
                        name="company_name"
                        placeholder="COMPANY NAME"
                        value={props.formData.company_name}
                        onChange={props.handleInputChange} 
                    />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                    <Form.Label></Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                        type="email"
                        name="email"
                        placeholder="EMAIL*"
                        value={props.formData.email}
                        onChange={props.handleInputChange}
                        aria-describedby="inputGroupPrepend"
                        required
                        />
                        <Form.Control.Feedback type="invalid">
                        Please enter email.
                        </Form.Control.Feedback>
                    </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                    <Form.Label></Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                        type="tel"
                        name="phone_no"
                        placeholder="PHONE NO*"
                        value={props.formData.phone_no}
                        onChange={props.handleInputChange}
                        aria-describedby="inputGroupPrepend"
                        required
                        />
                        <Form.Control.Feedback type="invalid">
                        Please enter phone no.
                        </Form.Control.Feedback>
                    </InputGroup>
                    </Form.Group>
                </Row>
                <Row className="mb-3 mbformrow">
                <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                    <Form.Label></Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                        type="text"
                        name="streetline1"
                        placeholder="Street line 1*"
                        value={props.formData.streetline1}
                        onChange={props.handleInputChange}
                        aria-describedby="inputGroupPrepend"
                        required
                        />
                        <Form.Control.Feedback type="invalid">
                        Please enter billing address.
                        </Form.Control.Feedback>
                    </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                    <Form.Label></Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                        type="text"
                        name="streetline2"
                        placeholder="Street line 2"
                        value={props.formData.streetline2}
                        onChange={props.handleInputChange}
                        aria-describedby="inputGroupPrepend"                  
                        />
                    </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom04">
                    <Form.Label></Form.Label>
                    <Form.Control type="text" name="city" placeholder="City*" value={props.formData.city} onChange={props.handleInputChange} required />
                    <Form.Control.Feedback type="invalid">
                        Please enter city.
                    </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom05">
                    <Form.Label></Form.Label>
                    <Form.Control required type="text" name="zip" value={props.formData.zip} onChange={props.handleInputChange} placeholder="Zip" />  
                    <Form.Control.Feedback type="invalid">
                        Please enter Zip.
                    </Form.Control.Feedback>            
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationCustom05">
                    <Form.Label></Form.Label>
                    <Form.Select aria-label="Country*" name="country" value={props.formData.country} onChange={props.handleInputChange}  required>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="AFGHANISTAN">Afghanistan</option>
                        <option value="ALBANIA">Albania</option>
                        <option value="ALGERIA">Algeria</option>
                        <option value="AMERICAN SAMOA">American Samoa</option>
                        <option value="ANDORRA">Andorra</option>
                        <option value="ANGOLA">Angola</option>
                        <option value="ANGUILLA">Anguilla</option>
                        <option value="ANTARCTICA">Antarctica</option>
                        <option value="ANTIGUA AND BARBUDA">Antigua and Barbuda</option>
                        <option value="ARGENTINA">Argentina</option>
                        <option value="ARMENIA">Armenia</option>
                        <option value="ARUBA">Aruba</option>
                        <option value="AUSTRALIA">Australia</option>
                        <option value="AUSTRIA">Austria</option>
                        <option value="AZERBAIJAN">Azerbaijan</option>
                        <option value="BAHAMAS">Bahamas</option>
                        <option value="BAHRAIN">Bahrain</option>
                        <option value="BANGLADESH">Bangladesh</option>
                        <option value="BARBADOS">Barbados</option>
                        <option value="BELARUS">Belarus</option>
                        <option value="BELGIUM">Belgium</option>
                        <option value="BELIZE">Belize</option>
                        <option value="BENIN">Benin</option>
                        <option value="BERMUDA">Bermuda</option>
                        <option value="BHUTAN">Bhutan</option>
                        <option value="BOLIVIA">Bolivia</option>
                        <option value="BOSNIA AND HERZEGOVINA">Bosnia and Herzegovina</option>
                        <option value="BOTSWANA">Botswana</option>
                        <option value="BOUVET ISLAND">Bouvet Island</option>
                        <option value="BRAZIL">Brazil</option>
                        <option value="BRITISH INDIAN OCEAN TERRITORY">British Indian Ocean Territory</option>
                        <option value="BRUNEI DARUSSALAM">Brunei Darussalam</option>
                        <option value="BULGARIA">Bulgaria</option>
                        <option value="BURKINA FASO">Burkina Faso</option>
                        <option value="BURUNDI">Burundi</option>
                        <option value="CAMBODIA">Cambodia</option>
                        <option value="CAMEROON">Cameroon</option>
                        <option value="CANADA">Canada</option>
                        <option value="CAPE VERDE">Cape Verde</option>
                        <option value="CAYMAN ISLANDS">Cayman Islands</option>
                        <option value="CENTRAL AFRICAN REPUBLIC">Central African Republic</option>
                        <option value="CHAD">Chad</option>
                        <option value="CHILE">Chile</option>
                        <option value="CHINA">China</option>
                        <option value="CHRISTMAS ISLAND">Christmas Island</option>
                        <option value="COCOS (KEELING) ISLANDS">Cocos (Keeling) Islands</option>
                        <option value="COLOMBIA">Colombia</option>
                        <option value="COMOROS">Comoros</option>
                        <option value="CONGO">Congo</option>
                        <option value="CONGO, THE DEMOCRATIC REPUBLIC OF THE">Congo, The Democratic Republic of the</option>
                        <option value="COOK ISLANDS">Cook Islands</option>
                        <option value="COSTA RICA">Costa Rica</option>
                        <option value="COTE D'IVOIRE">Cote d'Ivoire</option>
                        <option value="CROATIA">Croatia</option>
                        <option value="CUBA">Cuba</option>
                        <option value="CYPRUS">Cyprus</option>
                        <option value="CZECH REPUBLIC">Czech Republic</option>
                        <option value="DENMARK">Denmark</option>
                        <option value="DJIBOUTI">Djibouti</option>
                        <option value="DOMINICA">Dominica</option>
                        <option value="DOMINICAN REPUBLIC">Dominican Republic</option>
                        <option value="ECUADOR">Ecuador</option>
                        <option value="EGYPT">Egypt</option>
                        <option value="EL SALVADOR">El Salvador</option>
                        <option value="EQUATORIAL GUINEA">Equatorial Guinea</option>
                        <option value="ERITREA">Eritrea</option>
                        <option value="ESTONIA">Estonia</option>
                        <option value="ETHIOPIA">Ethiopia</option>
                        <option value="FALKLAND ISLANDS (MALVINAS)">Falkland Islands (Malvinas)</option>
                        <option value="FAROE ISLANDS">Faroe Islands</option>
                        <option value="FIJI">Fiji</option>
                        <option value="FINLAND">Finland</option>
                        <option value="FRANCE">France</option>
                        <option value="FRENCH GUIANA">French Guiana</option>
                        <option value="FRENCH POLYNESIA">French Polynesia</option>
                        <option value="FRENCH SOUTHERN TERRITORIES">French Southern Territories</option>
                        <option value="GABON">Gabon</option>
                        <option value="GAMBIA">Gambia</option>
                        <option value="GEORGIA">Georgia</option>
                        <option value="GERMANY">Germany</option>
                        <option value="GHANA">Ghana</option>
                        <option value="GIBRALTAR">Gibraltar</option>
                        <option value="GREECE">Greece</option>
                        <option value="GREENLAND">Greenland</option>
                        <option value="GRENADA">Grenada</option>
                        <option value="GUADELOUPE">Guadeloupe</option>
                        <option value="GUAM">Guam</option>
                        <option value="GUATEMALA">Guatemala</option>
                        <option value="GUINEA">Guinea</option>
                        <option value="GUINEA-BISSAU">Guinea-Bissau</option>
                        <option value="GUYANA">Guyana</option>
                        <option value="HAITI">Haiti</option>
                        <option value="HEARD ISLAND AND MCDONALD ISLANDS">Heard Island and McDonald Islands</option>
                        <option value="HOLY SEE (VATICAN CITY STATE)">Holy See (Vatican City State)</option>
                        <option value="HONDURAS">Honduras</option>
                        <option value="HONG KONG">Hong Kong</option>
                        <option value="HUNGARY">Hungary</option>
                        <option value="ICELAND">Iceland</option>
                        <option value="INDIA">India</option>
                        <option value="INDONESIA">Indonesia</option>
                        <option value="IRAN, ISLAMIC REPUBLIC OF">Iran, Islamic Republic of</option>
                        <option value="IRAQ">Iraq</option>
                        <option value="IRELAND">Ireland</option>
                        <option value="ISRAEL">Israel</option>
                        <option value="ITALY">Italy</option>
                        <option value="JAMAICA">Jamaica</option>
                        <option value="JAPAN">Japan</option>
                        <option value="JORDAN">Jordan</option>
                        <option value="KAZAKHSTAN">Kazakhstan</option>
                        <option value="KENYA">Kenya</option>
                        <option value="KIRIBATI">Kiribati</option>
                        <option value="KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF">Korea, Democratic People's Republic of</option>
                        <option value="KOREA, REPUBLIC OF">Korea, Republic of</option>
                        <option value="KUWAIT">Kuwait</option>
                        <option value="KYRGYZSTAN">Kyrgyzstan</option>
                        <option value="LAO PEOPLE'S DEMOCRATIC REPUBLIC">Lao People's Democratic Republic</option>
                        <option value="LATVIA">Latvia</option>
                        <option value="LEBANON">Lebanon</option>
                        <option value="LESOTHO">Lesotho</option>
                        <option value="LIBERIA">Liberia</option>
                        <option value="LIBYAN ARAB JAMAHIRIYA">Libyan Arab Jamahiriya</option>
                        <option value="LIECHTENSTEIN">Liechtenstein</option>
                        <option value="LITHUANIA">Lithuania</option>
                        <option value="LUXEMBOURG">Luxembourg</option>
                        <option value="MACAO">Macao</option>
                        <option value="MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF">Macedonia, The Former Yugoslav Republic of</option>
                        <option value="MADAGASCAR">Madagascar</option>
                        <option value="MALAWI">Malawi</option>
                        <option value="MALAYSIA">Malaysia</option>
                        <option value="MALDIVES">Maldives</option>
                        <option value="MALI">Mali</option>
                        <option value="MALTA">Malta</option>
                        <option value="MARSHALL ISLANDS">Marshall Islands</option>
                        <option value="MARTINIQUE">Martinique</option>
                        <option value="MAURITANIA">Mauritania</option>
                        <option value="MAURITIUS">Mauritius</option>
                        <option value="MAYOTTE">Mayotte</option>
                        <option value="MEXICO">Mexico</option>
                        <option value="MICRONESIA, FEDERATED STATES OF">Micronesia, Federated States of</option>
                        <option value="MOLDOVA, REPUBLIC OF">Moldova, Republic of</option>
                        <option value="MONACO">MONACO</option>
                        <option value="MONGOLIA">MONGOLIA</option>
                        <option value="MONTSERRAT">MONTSERRAT</option>
                        <option value="MOROCCO">MOROCCO</option>
                        <option value="MOZAMBIQUE">MOZAMBIQUE</option>
                        <option value="MYANMAR">MYANMAR</option>
                        <option value="NAMIBIA">NAMIBIA</option>
                        <option value="NAURU">NAURU</option>
                        <option value="NEPAL">NEPAL</option>
                        <option value="NETHERLANDS">NETHERLANDS</option>
                        <option value="NETHERLANDS ANTILLES">NETHERLANDS ANTILLES</option>
                        <option value="NEW CALEDONIA">NEW CALEDONIA</option>
                        <option value="NEW ZEALAND">NEW ZEALAND</option>
                        <option value="NICARAGUA">NICARAGUA</option>
                        <option value="NIGER">NIGER</option>
                        <option value="NIGERIA">NIGERIA</option>
                        <option value="NIUE">NIUE</option>
                        <option value="NORFOLK ISLAND">NORFOLK ISLAND</option>
                        <option value="NORTHERN MARIANA ISLANDS">NORTHERN MARIANA ISLANDS</option>
                        <option value="NORWAY">NORWAY</option>
                        <option value="OMAN">OMAN</option>
                        <option value="PAKISTAN">PAKISTAN</option>
                        <option value="PALAU">PALAU</option>
                        <option value="PALESTINIAN TERRITORY, OCCUPIED">PALESTINIAN TERRITORY, OCCUPIED</option>
                        <option value="PANAMA">PANAMA</option>
                        <option value="PAPUA NEW GUINEA">PAPUA NEW GUINEA</option>
                        <option value="PARAGUAY">PARAGUAY</option>
                        <option value="PERU">PERU</option>
                        <option value="PHILIPPINES">PHILIPPINES</option>
                        <option value="PITCAIRN">PITCAIRN</option>
                        <option value="POLAND">POLAND</option>
                        <option value="PORTUGAL">PORTUGAL</option>
                        <option value="PUERTO RICO">PUERTO RICO</option>
                        <option value="QATAR">QATAR</option>
                        <option value="REUNION">REUNION</option>
                        <option value="ROMANIA">ROMANIA</option>
                        <option value="RUSSIAN FEDERATION">RUSSIAN FEDERATION</option>
                        <option value="RWANDA">RWANDA</option>
                        <option value="SAINT HELENA">SAINT HELENA</option>
                        <option value="SAINT KITTS AND NEVIS">SAINT KITTS AND NEVIS</option>
                        <option value="SAINT LUCIA">SAINT LUCIA</option>
                        <option value="SAINT PIERRE AND MIQUELON">SAINT PIERRE AND MIQUELON</option>
                        <option value="SAINT VINCENT AND THE GRENADINES">SAINT VINCENT AND THE GRENADINES</option>
                        <option value="SAMOA">SAMOA</option>
                        <option value="SAN MARINO">SAN MARINO</option>
                        <option value="SAO TOME AND PRINCIPE">SAO TOME AND PRINCIPE</option>
                        <option value="SAUDI ARABIA">SAUDI ARABIA</option>
                        <option value="SENEGAL">SENEGAL</option>
                        <option value="SERBIA AND MONTENEGRO">SERBIA AND MONTENEGRO</option>
                        <option value="SEYCHELLES">SEYCHELLES</option>
                        <option value="SIERRA LEONE">SIERRA LEONE</option>
                        <option value="SINGAPORE">SINGAPORE</option>
                        <option value="SLOVAKIA">SLOVAKIA</option>
                        <option value="SLOVENIA">SLOVENIA</option>
                        <option value="SOLOMON ISLANDS">SOLOMON ISLANDS</option>
                        <option value="SOMALIA">SOMALIA</option>
                        <option value="SOUTH AFRICA">SOUTH AFRICA</option>
                        <option value="SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS">SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS</option>
                        <option value="SPAIN">SPAIN</option>
                        <option value="SRI LANKA">SRI LANKA</option>
                        <option value="SUDAN">SUDAN</option>
                        <option value="SURINAME">SURINAME</option>
                        <option value="SVALBARD AND JAN MAYEN">SVALBARD AND JAN MAYEN</option>
                        <option value="SWAZILAND">SWAZILAND</option>
                        <option value="SWEDEN">SWEDEN</option>
                        <option value="SWITZERLAND">SWITZERLAND</option>
                        <option value="SYRIAN ARAB REPUBLIC">SYRIAN ARAB REPUBLIC</option>
                        <option value="TAIWAN, PROVINCE OF CHINA">TAIWAN, PROVINCE OF CHINA</option>
                        <option value="TAJIKISTAN">TAJIKISTAN</option>
                        <option value="TANZANIA, UNITED REPUBLIC OF">TANZANIA, UNITED REPUBLIC OF</option>
                        <option value="THAILAND">THAILAND</option>
                        <option value="TIMOR-LESTE">TIMOR-LESTE</option>
                        <option value="TOGO">TOGO</option>
                        <option value="TOKELAU">TOKELAU</option>
                        <option value="TONGA">TONGA</option>
                        <option value="TRINIDAD AND TOBAGO">TRINIDAD AND TOBAGO</option>
                        <option value="TUNISIA">TUNISIA</option>
                        <option value="TURKEY">TURKEY</option>
                        <option value="TURKMENISTAN">TURKMENISTAN</option>
                        <option value="TURKS AND CAICOS ISLANDS">TURKS AND CAICOS ISLANDS</option>
                        <option value="TUVALU">TUVALU</option>
                        <option value="UGANDA">UGANDA</option>
                        <option value="UKRAINE">UKRAINE</option>
                        <option value="UNITED ARAB EMIRATES">UNITED ARAB EMIRATES</option>
                        <option value="UNITED KINGDOM">UNITED KINGDOM</option>
                        <option value="UNITED STATES">UNITED STATES</option>
                        <option value="UNITED STATES MINOR OUTLYING ISLANDS">UNITED STATES MINOR OUTLYING ISLANDS</option>
                        <option value="URUGUAY">URUGUAY</option>
                        <option value="UZBEKISTAN">UZBEKISTAN</option>
                        <option value="VANUATU">VANUATU</option>
                        <option value="VENEZUELA">VENEZUELA</option>
                        <option value="VIET NAM">VIET NAM</option>
                        <option value="VIRGIN ISLANDS, BRITISH">VIRGIN ISLANDS, BRITISH</option>
                        <option value="VIRGIN ISLANDS, U.S.">VIRGIN ISLANDS, U.S.</option>
                        <option value="WALLIS AND FUTUNA">WALLIS AND FUTUNA</option>
                        <option value="WESTERN SAHARA">WESTERN SAHARA</option>
                        <option value="YEMEN">YEMEN</option>
                        <option value="ZAMBIA">ZAMBIA</option>
                        <option value="ZIMBABWE">ZIMBABWE</option>

                    </Form.Select>            
                    </Form.Group>
                </Row>
                
                {props.isSubmitting ? (
                           <Button type="submit" disabled>Sending...</Button>
                        ) : (
                            <Button type="submit">Register Now</Button>
                        )}
                </Form>
            </Modal.Body>
            <Modal.Footer>           
              
            </Modal.Footer>
          </Modal>
            ))}
        </>
    );
}
export default PayLaterPop;