import React, { useState,useEffect } from 'react'
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';
import searchClient from './searchClient';
import ConnectedSearchBox from './ConnectedSearchBox';
import { Link } from 'react-router-dom';
import Properties from '../properties.js';

const CustomHits = ({ hit }) => (
    <div>
      <Link to={Properties.BASE_URL+hit.cat_slug+"/"+hit.course_slug}><p>{hit.course_name}</p></Link>
    </div>
 );
 
  
const Search = ({currentRefinement, refine}) => {
    const [query, setQuery] = React.useState('');
  const handleSearch = (event) => {
    setQuery(event.currentTarget.value);
    //console.log(query);
  };
    return (
        <>
            <div className="search-box">
                <InstantSearch searchClient={searchClient} indexName="Aiinox" >
                    <ConnectedSearchBox  handleSearch={handleSearch} placeholder="What area of AI would you like to explore ?"/>
                    {query && ( 
                    <Hits hitComponent={CustomHits} />
                    )}
                </InstantSearch> 
            </div>
        </>
    );
}
export default Search;