import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Properties from '../properties.js';
import { useNavigate } from 'react-router-dom';



function Login() {

  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      const formData = new FormData(event.target);
      const password = formData.get('password');
      if (password === "17235") {
        setShowSuccess(true);
        setShowError(false);
        localStorage.setItem("islogin",true);
        // navigate('/home');
        window.location.href = "/home";
      } else {
        setShowSuccess(false);
        setShowError(true);
        event.stopPropagation();
      }
    }

    setValidated(true);
  };

  return (
    <>
    <div className='log-wpr'>
    <div className='log-sec'>
    <img src={Properties.IMAGE_PATH+`public/gallery/AIINOX_LOGO_POS_FINAL.png`} alt="logo" className='log' />
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="validationCustom01">
          <Form.Label>Password</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              required
              type="number"
              name="password" 
              placeholder="Enter Password"
              isInvalid={showError} // Mark as invalid when showError is true
              isValid={showSuccess} // Mark as valid when showSuccess is true
              style={{ border: showError ? '1px solid red' : showSuccess ? '1px solid green' : '1px solid #ced4da' }} // Custom border style
            />
            
            
            <Form.Control.Feedback type="invalid">
              Please enter the correct password.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      
      <Button type="submit">Login</Button>
    </Form>
    </div>
    </div>
    </>
  );
}

export default Login;
