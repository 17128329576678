import React, { useEffect,useState,useCallback } from 'react'
//import { useHistory } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import $ from "jquery";
import Header from './Header';
import Footer from './Footer';
import {getCourseDetails,GetDelivery,RegisterPayPostData,OutlinePostData,DeliveryPostData,GetCms} from '../services/Api';
import Properties from '../properties.js';
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form';
import { Link,useLocation,useNavigate } from "react-router-dom";
import DeliveryMode from './DeliveryMode';
import PayLaterPop from './PayLaterPop.jsx';
import OutlinePop from './OutlinePop.jsx';
import Swal from 'sweetalert2';

const CategoryDetails = (props) => {
    //console.log(props.catSlug);
    const [expandedIndex, setExpandedIndex] = useState(0);      
    const [getCourseData, setCourseData] = useState([]);      
    const [accordionData, setaccordionData] = useState([]);      
    const [firstDate, setFirstDate] = useState([]);
    const [deliveryData, setDeliveryMode] = useState([]);      
    const [getDeliverydate, setDeliverydate] = useState();      
    const [getSelecteddate, setSelecteddate] = useState();      
    const [getaddToCart, setaddToCart] = useState(1);      
    const [getCurrencyWisePrice, setCurrencyWisePrice] = useState();  
    const [getminiCartpop, setminiCartpop] = useState(false);  
    const [getOutLinestate,setOutLinestate] = useState(false);
    const [validated, setValidated] = useState(false);
    const [getvenue, setvenue] = useState();  
    const [cmsData, setCmsMode] = useState([]); 
    const [pageData, setCmsPageData] = useState([]); 
    const location = useLocation(); 
    const navigate = useNavigate();
    const [deliveryState, setDeliverystate] = useState(false); 
    const [deliveryModeState, setDeliveryModestate] = useState(false); 
    const [deliveryStateSlug, setDeliverystateSlug] = useState(false); 
    const [formData, setFormData] = useState({
        full_name: '',
        company_name: '',
        email: '',
        phone_no: '',
        country: '',
        streetline1: '',
        streetline2:'',
        zip: '',
        });
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [OutlineformData, setOutlineFormData] = useState({
        f_name: '',
        email: '',
        phone_no: '',
        l_name: '',
        });
    const [OutLineisSubmitting, setoutLineIsSubmitting] = useState(false); 
    const showAlert = (text) => {
        Swal.fire({
          title: '',
          text: text,
          icon: 'success',
          confirmButtonText: 'Close'
        });
      };
    const handleToggle = (index) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const getCourseDetailsData = async (catSlug,courseSlug) => {
        await getCourseDetails(catSlug,courseSlug).then(courseResponse => {
            console.log(courseResponse);
            if (courseResponse.status===200) {
                //console.log("courseResponse",courseResponse.data);
                setCourseData(courseResponse.data)         
            }
        })
    }
    const getCourseContent =  () => {
        if (getCourseData.date_venue?.length) {
            const scheduleDate = getCourseData.date_venue.map((item) => (
                //console.log("Item",item.course_schedule),
                <ul key={item.id}>
                    <li>{item.course_schedule} {item.start_time} {item.end_time}</li>
                </ul>
            ));
            let trainersdata;
            if(getCourseData?.trainers){
                trainersdata = getCourseData.trainers.map((trainer) => (
                    //console.log("Item",item.course_schedule),
                    <div className='trainers'>
                        {(trainer?.image && /\.(png|jpe?g)$/i.test(trainer.image)) ? <img src={trainer.image} alt="Trainer" /> : ""}
                        <h4>{trainer.name}</h4>
                        <div dangerouslySetInnerHTML={{ __html: trainer.description }} />
                    </div>
                ));
            }
            let creditationdata;
            if(getCourseData?.creditation){
                creditationdata = getCourseData.creditation.map((cred) => (
                    //console.log("Item",cred),
                    <div className='trainers'>
                        {(cred?.image && /\.(png|jpe?g)$/i.test(cred.image)) ? <img src={cred.image} alt="Creditation" /> : ""}
                        <h4>{cred.name}</h4>
                        <div dangerouslySetInnerHTML={{ __html: cred.description }} />
                    </div>
                ));
            }
    
            const accordion = [
                { title: 'Course Overview', content: getCourseData.overview },
                { title: 'Course Objective', content: getCourseData.objective },
                { title: 'Course Outline', content: getCourseData.outline },               
                { title: 'Scheduled Dates', content: scheduleDate },
                { title: 'Certificate', content: (getCourseData?.certificate?.length)?getCourseData?.certificate: null},
                { title: 'Who Should Attend', content: getCourseData.wsa },
                { title: 'Trainers', content: (trainersdata?.length)?trainersdata :null },
                { title: 'Accreditation', content: (creditationdata?.length)?creditationdata :null },
            ];
    
            setaccordionData(accordion);
            //console.log("here",accordion)
            const firstDateVenue = getCourseData.date_venue[0];
            //console.log("firstDateVenue===>",firstDateVenue);
            setFirstDate(firstDateVenue);
            setSelecteddate(firstDateVenue.course_schedule);
            setDeliverydate(firstDateVenue.date_venue_id);
            setvenue(firstDateVenue.venue);
            
        }
    }
    const GetDeliveryData = async () => {
        await GetDelivery("all").then(deliveryresponse => {
           if (deliveryresponse.status===200) {
              //console.log(" Del Data",deliveryresponse.data)
              setDeliveryMode(deliveryresponse.data)         
           }
        })
     }
    const dateVanueCheck = useCallback((event) => {
         const selectedValue = event.target.value;
         const selectedDate = event.target.getAttribute('date');
         const venue = event.target.getAttribute('venue');
         setSelecteddate(selectedDate);
         //console.log("selectedValue",selectedDate);
        setDeliverydate(selectedValue);
        setvenue(venue);
        //setaddToCart(selectedValue);
    }, []);
    
    useEffect(() => {
        setCourseData([]);
        GetDeliveryData();
        getCourseDetailsData(props.catSlug,props.courseSlug);
    }, [location]);
    useEffect(() => {
        getCourseContent();
        currencyPrice();
    },[getCourseData.date_venue?.length, location]);
    
    
    const [cartData, setCartData] = useState([]);

    // Function to add an item to the cart
    const addToCart = (item) => {        
        //console.log("cartData===>",item);
        const dateVenue = getCourseData.date_venue;
        //console.log("dateVenue====>",dateVenue)
        const dtvencolm = dateVenue.map(row => row['date_venue_id']);
        const locdt = JSON.parse(localStorage.getItem('cart'));
        
        const uniqueValuesFromCart = (locdt?.length > 0) ? locdt.filter(item => !dtvencolm.includes(item)):[];
        //console.log("item====>", uniqueValuesFromCart);
        //setCartData(cartData);

        const updatedCart = [...uniqueValuesFromCart, Number(item)];
        //console.log("updatedCart===>",updatedCart);
        setCartData(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart)); // Store updated cart in local storage
        setminiCartpop(true);

        const courseCatloc = JSON.parse(localStorage.getItem('coursecat'));
        const courseCatjoin = props.catSlug+"/"+props.courseSlug;
        const newCatcrsSlg = (courseCatloc?.length > 0) ? courseCatloc.filter((catslg) => catslg !== courseCatjoin) : [];


        const updatedCartCoursecat = [...newCatcrsSlg, courseCatjoin];
        localStorage.setItem('coursecat', JSON.stringify(updatedCartCoursecat));
    };

    const miniCartClose = useCallback(() => {
        console.log("miniCartClose");
        setminiCartpop(false);
    },[getminiCartpop])

    // Function to clear the cart
    const clearCart = () => {
        setCartData([]);
        localStorage.removeItem('cart'); // Remove cart data from local storage
    };
    //var accordionData = [];
    //const seoData = getCourseData.date_venue.filter(item => item.date_venue.cat_slug === curTab);
    
    //console.log(getCourseData.overview);
    const currencyPrice = () => {
        const currency = localStorage.getItem('currency');
        switch (currency) {
            case 'pound':
                const poundPrice = parseFloat(getCourseData.pound_price);
                const currencyWisePrice = poundPrice ? "£ " + poundPrice.toLocaleString() : "£ "+0;

                setCurrencyWisePrice(currencyWisePrice);
                break;
            case 'doller':
                const dollerPrice = parseFloat(getCourseData.dollar_price);
                const dollercurrencyWisePrice = dollerPrice ? "$ " + dollerPrice.toLocaleString() : "$ " + 0;
                setCurrencyWisePrice(dollercurrencyWisePrice);
                break;
            default:
                const euroPrice = parseFloat(getCourseData.euro_price);
                const eurocurrencyWisePrice = euroPrice ? "€ " + euroPrice.toLocaleString() : "€ "+ 0;
                setCurrencyWisePrice(eurocurrencyWisePrice);
                break;
        }
    }

    const handlePopupClose = (slug) =>{
        //console.log("slug=====>",slug)
        setDeliverystate(false);
        setDeliverystateSlug(slug);
    } 
    const handleOutlinePopupClose = () =>{
        //console.log("slug=====>",slug)
        setOutLinestate(false);
        //setDeliverystateSlug(slug);
    } 
  
      
      // Function to handle changes in form inputs
      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
      };
      const handleOutlineInputChange = (event) => {
        const { name, value } = event.target;
        setOutlineFormData({ ...OutlineformData, [name]: value });
      };
    
      // Function to handle form submission
      const handleSubmit = async (event,courseDls) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        }else{
            setIsSubmitting(true);
            const updatedFormData = { ...formData, courseDls };
            await RegisterPayPostData(updatedFormData).then(data => {
            if (data.status === 200) {
                setFormData({
                    full_name: '',
                    company_name: '',
                    email: '',
                    phone_no: '',
                    country: '',
                    streetline1: '',
                    streetline2:'',
                    zip: '',
                  });
                setIsSubmitting(false);
                setDeliverystate(false);
                showAlert(`Thank you for registering for our ${courseDls?.courseName} course. A member of our team will be in touch shortly to assist you.`);
                // setDeliverystateSlug(slug);
            }
            })
        }
        // You can send form data to an API, or perform any other action
      };
      const OutlinehandleSubmit = async (event,courseDls) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        }else{
            setoutLineIsSubmitting(true);
            const updatedFormData = { ...OutlineformData, courseDls };
            await OutlinePostData(updatedFormData).then(data => {
                if (data.status === 200) {
                    setOutlineFormData({
                        f_name: '',
                        email: '',
                        phone_no: '',
                        l_name: '',
                      });
                    setoutLineIsSubmitting(false);
                    setOutLinestate(false);
                    // setDeliverystateSlug(slug);
                    showAlert('Thank you, please check your email for the download link to the course outline.');
                }
            })
        }
        // You can send form data to an API, or perform any other action
      };
      const DeliveryClick = (event,slug) => {
        event.preventDefault();        
        setDeliverystate(true); 
        setDeliverystateSlug(slug);
      }
      const OutlineForm = (event,slug)=>{
        event.preventDefault();        
        setOutLinestate(true);
        setDeliverystateSlug(slug); 
      }
      const DeliveryModeClick = (event,slug) => {
        event.preventDefault();
        //console.log("Delvery click===>",slug)
        if(slug==="classroom-training")
        //    navigate('/ai-industrial-markets');
            setDeliveryModestate(false);
        else if(slug==="onsite-instructor-led-training")
           navigate('/business-consultation');
        else
           setDeliveryModestate(true); 
           setDeliverystateSlug(slug);
      }
      const handledelModePopupClose = (slug) =>{
        //console.log("slug=====>",slug)
        setDeliveryModestate(false);
        setDeliverystateSlug(slug);
      } 
      const handleDelModeInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
      };
    
      // Function to handle form submission
      const handleDelModeSubmit = async (event,slug,delivery_mode) => {
        event.preventDefault();
        setIsSubmitting(true);
        const updatedFormData = { ...formData, delivery_mode };
        setFormData(updatedFormData);
        await DeliveryPostData(updatedFormData).then(data => {
           if (data.status === 200) {
              setIsSubmitting(false);
              setDeliveryModestate(false);
              setDeliverystateSlug(slug);
              showAlert(`Thank you for intersting for our ${delivery_mode} mode. A member of our team will be in touch shortly to assist you.`);
           }
         })
        // You can send form data to an API, or perform any other action
      };
      const GetCmsData = async () => {
        await GetCms('service').then(cmsresponse => {
            console.log("cmsresponse",cmsresponse);
           if (cmsresponse.status===200) {
            setCmsMode(cmsresponse.data);
            setCmsPageData(JSON.parse(cmsresponse.data?.page_details));       
           }
        })
    }
    useEffect(() => {
        GetCmsData();
    },[GetCmsData?.length])
    // useEffect(() => {
    //     currencyPrice();
    // },[getCourseData?.length])
    return (
      <> 
      {props.categoryList?.length>0 && <Header miniCartClose={miniCartClose} miniCart={getminiCartpop} cartData={cartData} clsName={"inner-header"} currencycng={currencyPrice} mataTitle={getCourseData.meta_title || ''} metaDes={getCourseData.meta_desc || ''} categoryList={props.categoryList} />}
        <div className="ctd" style={{ background: 'url("'+Properties.IMAGE_PATH+'public/gallery/CourseDetailsbnr.png")' }}>
                <img src={getCourseData.cat_image || ''} alt="" />
            <div className="small_heading">{getCourseData.cat_name || ''}
            </div>
            <h1>{getCourseData.course_name??''}</h1>

        </div>
        <div className="course_pnl_wrper">
            <div className="container">
            <ul>
            {deliveryData.map((item,index) =>(
                <li key={index} className={(item.slug === getCourseData.delivery_slug)?'hvr':''}>
                    {/* <Link to={(item.slug === getCourseData.delivery_slug)?'javascript:void(0)':'/business-consultation'}> */}
                    <Link onClick={(event) => DeliveryModeClick(event,item.slug)}>
                        <span>
                            <img src={item.icon}></img>
                        </span>
                        <div className="sub_heading">{item.delivery_mode.split(":")[0] + ":"}</div>
                        <div className="learn_more">{item.delivery_mode.split(":")[1]}</div>
                    </Link>
                </li>
            ))}
            </ul>
        </div>
        </div>

        <section className="catabox py-5">
            <div className="container">
                <div className="bredcum"><Link to={`/${props.catSlug}`}  ><i className="fa-solid fa-arrow-left-long"></i> Back to Categories</Link></div>

                <div className="row">
                <div className="col-md-7 col-sm-12">
                    <div className="course_description">
                        <div className="crs_date_pnl">
                            <ul>
                            <li>
                                <img src={Properties.IMAGE_PATH+`public/gallery/CALENDARICON.svg`} />
                                <span>Date</span>
                                <p>{getSelecteddate || ''} </p>
                            </li>
                            <li>
                                <img src={Properties.IMAGE_PATH+`public/gallery/ck.svg`} />
                                <span>Duration</span>
                                <p>{getCourseData.course_duration} Days </p>
                            </li>
                            </ul>
                        </div>
                        <div id="accordion" className="myaccordion">
                        {/* {console.log(accordionData)} */}
                            {accordionData?.length > 0 && accordionData.map((item, index) => (
                                (item.content!==null)?
                                <div className="card" key={index}>
                                <div className="card-header" id={`heading${index}`}>
                                    <h2 className="mb-0">
                                    <button
                                        className="d-flex align-items-center justify-content-between btn btn-link"
                                        data-toggle="collapse"
                                        data-target={`#collapse${index}`}
                                        onClick={() => handleToggle(index)}
                                        aria-expanded={expandedIndex === index ? "true" : "false"}
                                        aria-controls={`collapse${index}`}
                                    >
                                        <div className="sub_heading">{item.title}</div>
                                        <span className="fa-stack fa-sm">
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className={`fas ${expandedIndex === index ? "fa-minus" : "fa-plus"} fa-stack-1x fa-inverse`}></i>
                                        </span>
                                    </button>
                                    </h2>
                                </div>
                                {expandedIndex === index && (
                                    <div
                                    id={`collapse${index}`} className="collapse show" aria-labelledby={`heading${index}`} data-parent="#accordion">
                                    <div className="card-body">
                                    {(item.title === 'Scheduled Dates' || item.title === 'Trainers'|| item.title === 'Accreditation') && item.content}
                                    {((item.title !== 'Scheduled Dates' && item.title !== 'Trainers' && item.title !== 'Accreditation') && item.content)  && <div dangerouslySetInnerHTML={{ __html: item.content }} />}
                                    </div>
                                    </div>
                                )}
                                </div>
                                :''
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-md-5 col-sm-12">
                    <div className="rt_cart_sec">                       

                        <div className="carts">
                            <div className="cart_top_box">
    
                            <img src={Properties.IMAGE_PATH+`public/gallery/INDIVIDUALCOURSESICON.svg`} alt="" />
    
                            <div className="cart_con">
                                {/* For Individuals */}
                                Upcoming dates
                                {/* <b> Upcoming dates</b> */}
                                <p>(select date & time below)</p>
    
                        

                                <form className="form ovr">
                                {getCourseData.date_venue?.length > 0 && getCourseData.date_venue.map((item, index) => (
                                    <div className="rdo">
                                        <label for="01">
                                            <strong> {item.course_schedule} </strong>
                                            <span>{item.start_time} – {item.end_time}</span><br></br>
                                            <span>Location : {item.venue}</span>
                                        </label>
                                        <InputGroup.Radio key={index} name="scheduleDate" checked={item.date_venue_id==getDeliverydate} date={item.course_schedule} venue={item.venue} value={item.date_venue_id} onChange={dateVanueCheck} aria-label="Radio button for following text input" />                                       
                                    </div>
                                ))}
                                
                                </form>
                                    
                                <div class="sub_head">{getCurrencyWisePrice}</div>                           
                                {/* <span>(Inc. VAT)</span> */}
                            </div>
                            
                            </div>
                            
                            {/* {getCourseData.date_venue?.length && (
                                <a className="cart_btn2" onClick={() => addToCart(getDeliverydate)}> Add to cart </a>
                            )} */}
                            {/* {getCourseData.date_venue?.length && (
                                <a className="cart_btn2" onClick={() => addToCart(getDeliverydate)}> Register Now and Pay Later <span><img src={Properties.IMAGE_PATH + `public/gallery/TICKICON.svg`} /></span></a>
                            )} */}
                            {getCourseData.date_venue?.length && (
                                <a className="cart_btn2" onClick={(event) => DeliveryClick(event,getCourseData.delivery_slug,getCourseData.course_name,)}> Register Now and Pay Later 
                                    {/* <span><img src={Properties.IMAGE_PATH + `public/gallery/TICKICON.svg`} /></span> */}
                                </a>
                            )}
                             {getCourseData.date_venue?.length && (
                                <a className="cart_btn2 " onClick={(event) => OutlineForm(event,getCourseData.delivery_slug)}> Download Outline 
                                    {/* <span><img src={Properties.IMAGE_PATH + `public/gallery/TICKICON.svg`} /></span> */}
                                </a>
                            )}
                            </div>
                    </div>
                </div>
                </div>
                
            
            
            </div>
        </section>
        <div className="catabox clsroom">
            <div className="container"  dangerouslySetInnerHTML={{ __html: pageData?.in_company_training }}>
        </div>
        </div>
        <div className="business_list">
        <div className="container">
            <div className="small_heading">Our Values</div>
            <div className="heading">What Our Business Stands For</div>
            <ul className="bis-list">
                <li>
                <img src={Properties.IMAGE_PATH+`public/gallery/LIGHTBULB ICON.svg`} alt="" />
                <div className="sub_heading">INNOVATION</div>
                <p>Embracing constant learning, pushing boundaries, and exploring new frontiers in AI and technology.</p>
                </li>
                <li>
                <img src={Properties.IMAGE_PATH+`public/gallery/VIDEOCONFERENCE ICON.svg`} alt="" />
                <div className="sub_heading">INCLUSION</div>
                <p>Fostering a diverse and welcoming community where everyone can thrive in the tech world.</p>
                </li>
                <li>
                <img src={Properties.IMAGE_PATH+`public/gallery/ROSETTE ICON.svg`} alt="" />
                <div className="sub_heading">INTEGRITY</div>
                <p>Maintaining the highest ethical standards in AI development and application, prioritising human well-being.</p>
                </li>
                <li>
                <img src={Properties.IMAGE_PATH+`public/gallery/BRAIN ICON.svg`} alt="" />
                <div className="sub_heading">COLLABORATION</div>
                <p>Building bridges across disciplines and backgrounds, leveraging collective intelligence to drive meaningful change.</p>
                </li>
                <li>
                <img src={Properties.IMAGE_PATH+`public/gallery/DIGITAL GLOBE ICON.svg`} alt="" />
                <div className="sub_heading">IMPACT</div>
                <p>Focusing on solutions that address real-world challenges and contribute to a positive, sustainable future.</p>
                </li>
            </ul>
        </div>
    </div>
    <Footer />
    <PayLaterPop DeliveryData={deliveryData} DelSlug={deliveryStateSlug} handlePopupClose={handlePopupClose} validated={validated} formData={formData} courseDate={getSelecteddate} courseVenue={getvenue} courseName={getCourseData.course_name??''} isSubmitting={isSubmitting} handleSubmit={handleSubmit} handleInputChange={handleInputChange} DelState={deliveryState} />
    <OutlinePop DeliveryData={deliveryData} DelSlug={deliveryStateSlug} handlePopupClose={handleOutlinePopupClose}  validated={validated} formData={OutlineformData} courseDate={getSelecteddate} coursedateVenue={getDeliverydate} courseVenue={getvenue} courseName={getCourseData.course_name??''} isSubmitting={OutLineisSubmitting} handleSubmit={OutlinehandleSubmit} handleInputChange={handleOutlineInputChange} popshow={getOutLinestate} />
    <DeliveryMode DeliveryData={deliveryData} DelSlug={deliveryStateSlug} handlePopupClose={handledelModePopupClose} formData={formData} isSubmitting={isSubmitting} handleSubmit={handleDelModeSubmit} handleInputChange={handleDelModeInputChange} DelState={deliveryModeState} />
      </>
      )
  };
  
  
  export default CategoryDetails;