import React, { useState, useEffect } from 'react';
import { Modal,Button } from 'react-bootstrap';
import {DeliveryPostData} from '../services/Api';

function DeliveryMode(props) {
    const deliveryData = props.DeliveryData;
     //console.log(props.DelState);
    const show = props.DelState; 
    const courseDlsss = {
        courseName: props?.courseName ??'',
        courseDate: props?.courseDate ?? '',
        courseVenue: props?.courseVenue ??''
    };
  
    return(
        <>
        {/* {console.log(deliveryData)}  */}
        {deliveryData.map((item,index) => (        
            <Modal
            show={props.DelSlug === item.slug ? props.DelState : false}
            onHide={()=>props.handlePopupClose(item.slug)}
            keyboard={false}
            animation={false}
            dialogClassName="modal-90w"
          >
            <Modal.Header closeButton>
                <div className="mod-img">                    
                    <img src={item.icon} alt="" />                    
                </div>
                <div className="mod-heading">
                {props?.courseName?.length ? (
                     <>
                        <div className="small_heading">Course Delivery Modes</div>
                        <div className="heading">{item.delivery_mode}</div>                    
                        <div className="coursespop_heading"><span>Name :</span> {props?.courseName}</div>
                        <div className="coursespop_heading"><span>Date  :</span> {props?.courseDate}</div>
                        <div className="coursespop_heading"><span>Venue :</span> {props?.courseVenue}</div>
                        
                     </>
                  ) : (
                     <>
                        <div className="small_heading">Course Delivery Modes</div>
                        <div className="heading">{item.delivery_mode}</div>                    
                        <div className="infrst" style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)' }}>
                           Information Request
                        </div>
                     </>
                  )}
                </div>
            </Modal.Header>
            <Modal.Body>
            <form onSubmit={(event) => props.handleSubmit(event, item.slug,item.delivery_mode,courseDlsss)}>
                <div className="row">
                <div className="col-md-6 col-sm-12">
                  
                        <div className="styled-input wide">
                           <input type="text" placeholder='First Name' name="fname" required value={props.formData.fname} onChange={props.handleInputChange}  style={{color:"unset"}}/>
                           
                        </div>
                     </div>
                     <div className="col-md-6 col-sm-12">
                        <div className="styled-input wide">
                           <input type="text" placeholder='Last Name' name="lname" value={props.formData.lname} onChange={props.handleInputChange} required style={{color:"unset"}}/>
                        </div>
                     </div>
                     <div className="col-md-6 col-sm-12">
                        <div className="styled-input wide">
                           <input type="text" placeholder='Email' name="email" value={props.formData.email} onChange={props.handleInputChange} required style={{color:"unset"}}/>
                        </div>
                     </div>
                     <div className="col-md-6 col-sm-12">
                        <div className="styled-input wide">
                           <input type="number" placeholder='Phone Number' name="phone_no" value={props.formData.phone_no} onChange={props.handleInputChange} required style={{color:"unset"}}/>
                           
                        </div>
                     </div>
                     <div className="col-sm-12">
                        <div className="styled-input wide">
                           <input type="hidden" value={item.delivery_mode} name="delivery_mode" required style={{color:"unset"}}/>
                           
                        </div>
                     </div>
                     <div className="col-sm-12">
                        <div className="styled-input wide">
                           <textarea placeholder='Enter your message' name="message" value={props.formData.message} onChange={props.handleInputChange} required style={{color:"unset"}}></textarea>
                        </div>
                     </div>
                     <div className="col-xs-12">
                     {props.isSubmitting ? (
                           <button type="submit" className="btn-lrg submit-btn" disabled>
                              Sending...
                           </button>
                        ) : (
                           <button type="submit" className="btn-lrg submit-btn">
                              Submit
                           </button>
                        )}
                        {/* <div className="col-md-7 col-sm-12" dangerouslySetInnerHTML={{ __html: item.description}}></div>    */}
                     </div>
                  
                </div>
                </form>
            </Modal.Body>
            <Modal.Footer>           
              
            </Modal.Footer>
          </Modal>
            ))}
        </>
    );
}
export default DeliveryMode;