import React from 'react';
import useDelay from './useDelay'; // Adjust the import path accordingly
import Loader from './Loader'; // Adjust the import path accordingly

const DelayedComponent = ({ delay, children }) => {
  const isReady = useDelay(delay);
  return isReady ? children : <Loader />;
};

export default DelayedComponent;
