import React from 'react';
import './Loader.css'; // Optional: Add CSS for styling the loader

const Loader = () => {
  return (
    <div className="loader">
      <div className="spinner"></div>
      Loading...
    </div>
  );
};

export default Loader;
