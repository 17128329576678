import React,{useState,useEffect,memo} from 'react'
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Properties from '../properties.js';
import Header from './Header';
import Footer from './Footer';
import {GetDelivery} from '../services/Api'
//import logo from './image/logo.png';
const DeliveryModesPage = (props) => {
    const [deliveryData, setDeliveryMode] = useState([]); 
    const GetDeliveryData = async () => {
        await GetDelivery(props.slug).then(deliveryresponse => {
            //console.log("deliveryresponse",deliveryresponse);
           if (deliveryresponse.status===200) {
              setDeliveryMode(deliveryresponse.data)         
           }
        })
    }
    useEffect(() => {
        GetDeliveryData();
        window.scrollTo(0, 0);
    }, []);
  return (
    <>
        {props.categoryList.length>0 && <Header clsName={"inner-header"}  categoryList={props.categoryList} />}
        <div className="inner_banner_wrapper clr" style={{ background: 'url("'+Properties.IMAGE_PATH+'public/gallery/Banner_Cookie_Policy.png")' }}>
        
        <h1>{deliveryData?.delivery_mode}</h1>

    </div>
        <section className="catabox py-5">
    <div className="container" dangerouslySetInnerHTML={{ __html: deliveryData?.page_data }}>
    </div>
    </section>
    <Footer />
    </>
  )
}

export default DeliveryModesPage