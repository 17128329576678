import React, { useState, useEffect } from 'react';
import { Modal,Button } from 'react-bootstrap';
import {DeliveryPostData} from '../services/Api';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

function OutlinePop(props) {
    const deliveryData = props.DeliveryData;
     //console.log(props.DelState);
    
    const [getDelivery, setDelivery] = useState(false);
    //const [validated, setValidated] = useState(false);
    const courseDlsss = {
        courseName: props?.courseName ??'',
        courseDate: props?.courseDate ?? '',
        courseVenue: props?.courseVenue ??'',
        coursedateVenue: props?.coursedateVenue ??''    
    };
    // const handleSubmit = (event) => {
    //   const form = event.currentTarget;
    //   if (form.checkValidity() === false) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //   }
    
    //   setValidated(true);
    // };
    useEffect(() => {
        const matchingItem = deliveryData.find(item => item.slug === props.DelSlug);
        //console.log("matchingItem",deliveryData);
        if (matchingItem) {
          setDelivery(matchingItem.delivery_mode);
        }
      }, [props.DelSlug, deliveryData]);
    return(
        <>
        {/* {console.log(deliveryData)}  */}
            <Modal
            show={props.popshow}
            onHide={props.handlePopupClose}
            keyboard={false}
            animation={false}
            dialogClassName="modal-90w"
          >
            <Modal.Header closeButton>
                <div className="mod-img">                    
                </div>
                <div className="mod-heading">
                {props?.courseName?.length && 
                     <>
                        <div className="small_heading">Course Delivery Modes</div>
                        <div className="heading">{getDelivery}</div>                    
                        <div className="coursespop_heading"><span>Name :</span> {props?.courseName}</div>
                        <div className="coursespop_heading"><span>Date  :</span> {props?.courseDate}</div>
                        <div className="coursespop_heading"><span>Venue :</span> {props?.courseVenue}</div>
                        
                     </>
                  }
                </div>
            </Modal.Header>
            <Modal.Body>
            <Form noValidate validated={props.validated} onSubmit={(event) => props.handleSubmit(event,courseDlsss)}>
                <Row className="mb-3 mbformrow">
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Label></Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name="f_name"
                        placeholder="First NAME*"
                        value={props.formData.f_name}
                        onChange={props.handleInputChange} 
                    />
                    <Form.Control.Feedback  type="invalid">Enter First Name</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                    <Form.Label></Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name="l_name"
                        placeholder="Last NAME"
                        value={props.formData.l_name}
                        onChange={props.handleInputChange} 
                    />
                    <Form.Control.Feedback  type="invalid">Enter Last Name</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                    <Form.Label></Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                        type="email"
                        name="email"
                        placeholder="EMAIL*"
                        value={props.formData.email}
                        onChange={props.handleInputChange}
                        aria-describedby="inputGroupPrepend"
                        required
                        />
                        <Form.Control.Feedback type="invalid">
                        Please enter email.
                        </Form.Control.Feedback>
                    </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustomUsername">
                    <Form.Label></Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                        type="tel"
                        name="phone_no"
                        placeholder="PHONE NO*"
                        value={props.formData.phone_no}
                        onChange={props.handleInputChange}
                        aria-describedby="inputGroupPrepend"
                        required
                        />
                        <Form.Control.Feedback type="invalid">
                        Please enter phone no.
                        </Form.Control.Feedback>
                    </InputGroup>
                    </Form.Group>
                </Row>
                
                
                    {props.isSubmitting ? (
                           <Button type="submit" disabled>Sending...</Button>
                        ) : (
                            <Button type="submit">Download Outlines</Button>
                        )}
                
                </Form>
            </Modal.Body>
            <Modal.Footer>           
              
            </Modal.Footer>
          </Modal>
        </>
    );
}
export default OutlinePop;