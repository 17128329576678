import React, { createContext,useEffect, useState } from 'react';
import {GetCms} from '../services/Api'
import Footer from './Footer';
export const AccreditationContext = createContext();

const Accreditation = ({ children }) => {
  const [cmsData, setCmsMode] = useState([]); 
  const [pageData, setCmsPageData] = useState([]);
  const GetCmsData = async () => {
    await GetCms('accreditation').then(cmsresponse => {
        console.log("cmsresponse",cmsresponse);
       if (cmsresponse.status===200) {
        setCmsMode(cmsresponse.data);
        setCmsPageData(JSON.parse(cmsresponse.data?.page_details));       
       }
    })
  }
  useEffect(() => {
    GetCmsData();  
  }, []);
  const data = "here";
  return (
    <>
    {/* {pageData?.length > 0 && ( */}
      <AccreditationContext.Provider value={pageData}>
        {children}
      </AccreditationContext.Provider>
    {/* )} */}
    </>
  );
};

export default Accreditation;

