import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
const Checkout = (props) => {
  
  const [cardType, setCardType] = useState('');

  

  const handleCardNumberChange = async (e) => {
    const cardNumber = e.target.value;
    setCardNumber(cardNumber);

    if (cardNumber.length >= 4) {

      if (cardNumber.length >= 6) {
        const bin = cardNumber.slice(0, 6);
        try {
          const response = await fetch(`https://www.kogitodemo.com/hostedsites/aiinoxcrm/api/cardtype/${bin}`);
          if (response.ok) {
            const data = await response.json();
            setCardType(data.scheme);
          } else {
            setCardType('');
          }
        } catch (error) {
          console.error('Error fetching card type:', error);
          setCardType('');
        }
      } else {
        setCardType('');
      }
    } else {
      setCardType('');
    }
  };
  const [cardNumber, setCardNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [name, setName] = useState('');

  const handlePayment = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('https://www.kogitodemo.com/hostedsites/aiinoxcrm/api/payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: 1, // specify amount and currency
          currency: 'USD',
        }),
      });
      
      const data = await response.json();
      console.log('data',data);
      const { intent_id, client_secret } = data;
      return false;
      // Use Airwallex API directly to confirm payment intent
      const paymentResponse = await fetch(`https://api.airwallex.com/api/v1/pa/payment_intents/${intent_id}/confirm`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer 05c1d39a0fd1d059986d905d07f91f085e95f722eebd98cf26995cead15dbba9f6b4ee189f33fb238d8bf7391e8cf5fe`, // make sure to set this in your React environment variables
        },
        body: JSON.stringify({
          client_secret: client_secret,
          payment_method: {
            type: 'card',
            card: {
              number: cardNumber,
              expiry: expiry,
              cvc: cvc,
              name: name,
            },
          },
        }),
      });

      const paymentData = await paymentResponse.json();
      console.log('Payment successful', paymentData);
    } catch (error) {
      console.error('Payment failed', error);
    }
  };
  return (
    <div className='container'>


   
          <form onSubmit={handlePayment}   style={{ display: props.show ? 'block' : 'none' }}>
          <div className='row'>
          <div className='col-md-8 col-sm-12'>
                  <div className='heading_payment'>
              <h2><strong>Payment Method</strong> </h2>
              <div className='card_name'>
                  <span>Debit or Credit Card</span>
                  <span><img src="https://kogitodemo.com/hostedsites/ltfe/theme_v_1/images/cart_cards.png"></img></span>
              </div>
              <div className="dd">
          <div className="field-container">
          <label htmlFor="name">Name</label>
          <input id="name" maxLength={20} type="text" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="field-sec">
          <div className="field-container">
          <label htmlFor="cardnumber">Card Number</label>
          <div className="field-inputs">
          <img src="https://cdn-icons-png.flaticon.com/512/15398/15398152.png" />
          <input id="cardnumber" type="text" pattern="[0-9]*" inputMode="numeric" className='md' value={cardNumber} onChange={(e) => setCardNumber(e.target.value)}  />
          <input id="expirationdate" placeholder='MM/YY' type="text" inputMode="numeric"  value={expiry} onChange={(e) => setExpiry(e.target.value)} />
          <input id="securitycode" placeholder='CVV' type="text" pattern="[0-9]*" inputMode="numeric" className="cv"  value={cvc} onChange={(e) => setCvc(e.target.value)} />
          </div>
          </div>

          </div>
          </div>
          <p><img src='https://cdn-icons-png.flaticon.com/512/3064/3064155.png' width={15} /> Your transaction is secured with SSL encryption</p>

 
          </div>
          </div>
          <div className="col-md-4 checknergt bask">
              <h2><strong>Summary</strong> </h2>
              <p className="ttledel cart_addoff">
              </p><div className="imm_tott"><span style={{ fontWeight: 'bold' }}>Total</span> <strong className="gtotal">{props.price}</strong></div>
              <p />
              <div className="pay_gty positiobt">
              <Button type="submit">Book My Course</Button>                
                <div className="newcartcard"><img src="https://kogitodemo.com/hostedsites/ltfe/theme_v_1/images/cart_cards.png" /> </div>

                <div className="clr" />
              </div>
            </div> 
            </div>
        </form>
   
      
 

       
    </div>
  );
};

export default Checkout;
