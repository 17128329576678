import {API} from './config';
export const GetCategory = () => {
    return new Promise((resolve, reject) => {  
      fetch(API.GETCATEGORY)
        .then(response => {
          response.json().then(data => {
            if (data) {                
                resolve(data);
            } else {
                reject(data);
            }
          });
        })
        .catch(error => {
          reject({status: 400, message: 'Something went wrong!'});
        });
    });
  };

  export const GetDelivery = (type) => {
    let apiUrl=API.GETDELIVERY;
    if(type!=="all"){
      apiUrl = API.GETDELIVERY+"/"+type;
    }
    //console.log(apiUrl);
    return new Promise((resolve, reject) => {  
      fetch(apiUrl)
        .then(response => {
          response.json().then(data => {
            console.log(data);
            if (data) {                
                resolve(data);
            } else {
                reject(data);
            }
          });
        })
        .catch(error => {
          reject({status: 400, message: 'Something went wrong!'});
        });
    });
  };

  export const GetCourseByCat = () => {
    return new Promise((resolve, reject) => {  
      fetch(API.GETCOURSEBYCAT)
        .then(response => {
          response.json().then(data => {
            // console.log("API JS",data)
            if (data) {                
                resolve(data);
            } else {
                reject(data);
            }
          });
        })
        .catch(error => {
          reject({status: 400, message: 'Something went wrong!'});
        });
    });
  };

  export const getCourseDetails = (catSlug,courseSlug) => {
    return new Promise((resolve, reject) => {  
      fetch(API.GETCOURSEDETAILS+"/"+catSlug+"/"+courseSlug)
        .then(response => {
          response.json().then(data => {
            //console.log("details",data)
            if (data) {                
                resolve(data);
            } else {
                reject(data);
            }
          });
        })
        .catch(error => {
          reject({status: 400, message: 'Something went wrong!'});
        });
    });
  };

  export const getCartData = (cartdata) => {
    return new Promise((resolve, reject) => {    
      //console.log("CartPage===>",cartdata);
      const cart = cartdata;
      const formData = new FormData();
      if(Array.isArray(cart)){
        cart.map((item) => {
          formData.append('date_venue_ids[]', item);
        });
      } 
      
      fetch(API.CARTDETAILS, {
        method: 'POST',
        body: formData,
      })
        .then(response => {          
          response.json().then(data => {
            //console.log("cartDatapromise",data)
            if (data.status === 200) {
              resolve(data);
            } else {
              reject(data);
            }
          });
        })
        .catch(error => {
          reject({status: 400, message: 'Something went wrong!'});
        });
    });
  };

  export const GetCms = (type) => {
    let apiUrl = API.GETCMS+"/"+type;
    //console.log(apiUrl);return false;
    return new Promise((resolve, reject) => {  
      fetch(apiUrl)
        .then(response => {
          response.json().then(data => {
            //console.log(data);
            if (data) {                
                resolve(data);
            } else {
                reject(data);
            }
          });
        })
        .catch(error => {
          reject({status: 400, message: 'Something went wrong!'});
        });
    });
  };


  export const DeliveryPostData = (data) => {
    return new Promise((resolve, reject) => {    
      //console.log("data===>",data);return false
      const formData = new FormData();      
      formData.append('name', data.fname +" "+ data.lname);
      formData.append('email',  data.email);
      formData.append('phone_no',  data.phone_no);
      formData.append('f_subject',  data?.subject??'');
      formData.append('subject',  data?.delivery_mode??'');
      formData.append('message',  data.message);
      formData.append('coursenm',  data?.courseDls?.courseName??'');
      formData.append('coursedate',  data?.courseDls?.courseDate??'');
      formData.append('coursevenue',  data?.courseDls?.courseVenue??'');
      
      fetch(API.DELIVERYMODES, {
        method: 'POST',
        body: formData,
      })
        .then(response => {          
          response.json().then(data => {
            //console.log("cartDatapromise",data)
            if (data.status === 200) {
              resolve(data);
            } else {
              reject(data);
            }
          });
        })
        .catch(error => {
          reject({status: 400, message: 'Something went wrong!'});
        });
    });
  };
  export const RegisterPayPostData = (data) => {
    return new Promise((resolve, reject) => {    
       //console.log("data===>",data);return false
      const formData = new FormData();      
      formData.append('full_name', data?.full_name);
      formData.append('email',  data.email);
      formData.append('phone_no',  data.phone_no);
      formData.append('company_name',  data?.company_name);
      formData.append('streetline1',  data.streetline1);
      formData.append('streetline2',  data.streetline2);
      formData.append('city',  data.city);
      formData.append('country',  data.country);
      formData.append('zip',  data.zip);
      formData.append('coursenm',  data?.courseDls?.courseName??'');
      formData.append('coursedate',  data?.courseDls?.courseDate??'');
      formData.append('coursevenue',  data?.courseDls?.courseVenue??'');
      
      fetch(API.PAYLATER, {
        method: 'POST',
        body: formData,
      })
        .then(response => {          
          response.json().then(data => {
            //console.log("cartDatapromise",data)
            if (data.status === 200) {
              resolve(data);
            } else {
              reject(data);
            }
          });
        })
        .catch(error => {
          reject({status: 400, message: 'Something went wrong!'});
        });
    });
  };
  export const OutlinePostData = (data) => {
    return new Promise((resolve, reject) => {    
       //console.log("data===>",data);return false
      const formData = new FormData();      
      formData.append('f_name', data?.f_name);
      formData.append('email',  data.email);
      formData.append('phone_no',  data.phone_no);
      formData.append('l_name',  data?.l_name);      
      formData.append('coursenm',  data?.courseDls?.courseName??'');
      formData.append('currency',  localStorage?.getItem('currency')??'');
      formData.append('coursedate',  data?.courseDls?.courseDate??'');
      formData.append('coursevenue',  data?.courseDls?.courseVenue??'');
      formData.append('coursedateVenue',  data?.courseDls?.coursedateVenue??'');      
      //console.log("data===>",formData);return false
      fetch(API.OUTLINE, {
        method: 'POST',
        body: formData,
      })
        .then(response => {          
          response.json().then(data => {
            //console.log("cartDatapromise",data)
            if (data.status === 200) {
              resolve(data);
            } else {
              reject(data);
            }
          });
        })
        .catch(error => {
          reject({status: 400, message: 'Something went wrong!'});
        });
    });
  };
  export const ContactData = (data) => {
    return new Promise((resolve, reject) => {    
       //console.log("data===>",data);return false
      const formData = new FormData();      
      formData.append('firstName', data?.firstName);
      formData.append('email',  data.email);
      formData.append('message',  data.message);
      //console.log("data===>",formData);return false
      fetch(API.CONTACT, {
        method: 'POST',
        body: formData,
      })
        .then(response => {          
          response.json().then(data => {
            //console.log("cartDatapromise",data)
            if (data.status === 200) {
              resolve(data);
            } else {
              reject(data);
            }
          });
        })
        .catch(error => {
          reject({status: 400, message: 'Something went wrong!'});
        });
    });
  };
  export const CartBeforeePayPOST = (data) => {
    return new Promise((resolve, reject) => {    
       //console.log("data===>",data);return false
      const formData = new FormData();      
      formData.append('full_name', data?.full_name);
      formData.append('company_name',  data.company_name);
      formData.append('email',  data.email);
      formData.append('phone_no',  data.phone_no);
      formData.append('country',  data.country);
      formData.append('streetline1',  data.streetline1);
      formData.append('streetline2',  data.streetline2);
      formData.append('zip',  data.zip);
      formData.append('city',  data.city);
      formData.append('cart_data',  localStorage?.getItem('cartDetails')??'');
      formData.append('course_dt_ven_id',  (JSON.parse(localStorage?.getItem('cart'))).join()??'');
      //console.log("data===>",formData);return false
      fetch(API.CARTBEFOREPAY, {
        method: 'POST',
        body: formData,
      })
        .then(response => {          
          response.json().then(data => {
            //console.log("cartDatapromise",data)
            if (data.status === 200) {
              resolve(data);
            } else {
              reject(data);
            }
          });
        })
        .catch(error => {
          reject({status: 400, message: 'Something went wrong!'});
        });
    });
  };