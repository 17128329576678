
import React, { useState,useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2';
import {ContactData} from '../services/Api'
import Properties from '../properties.js';
import {GetCms} from '../services/Api'
import { useLocation } from 'react-router-dom';

import { Form, Button, InputGroup } from 'react-bootstrap';
const Contact = (props) => {
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cmsData, setCmsMode] = useState([]); 
  const [pageData, setCmsPageData] = useState([]);
  const recaptchaRef = React.useRef();
  const location = useLocation(); 
  const onChange = (value) => {
    console.log("Captcha value:", value);
  }
  const showAlert = () => {
    Swal.fire({
      title: '',
      text: 'Thank you one of our team members will respond shortly.',
      icon: 'success',
      confirmButtonText: 'Close'
    });
  };
  const [formData, setFormData] = useState({
    firstName: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {      
      event.stopPropagation();
    }else{
      setIsSubmitting(true);
      await ContactData(formData).then(data => {
         if (data.status === 200) {
            //setIsSubmitting(false);
            setIsSubmitting(false);
            showAlert();
         }
       })
    }
  
    setValidated(true);
  };
  const GetCmsData = async () => {
      await GetCms(props.slug).then(cmsresponse => {
          console.log("cmsresponse",cmsresponse);
        if (cmsresponse.status===200) {
          setCmsMode(cmsresponse.data);
          setCmsPageData(JSON.parse(cmsresponse.data?.page_details));       
        }
      })
  }
  useEffect(() => {
      GetCmsData();
      window.scrollTo(0, 0);
  }, [location,cmsData?.length]);
  return (<>
  {props.categoryList.length>0 && <Header clsName={"inner-header"}  mataTitle={pageData?.meta_title || ''} metaDes={pageData?.meta_desc || ''}  categoryList={props.categoryList} />}
    <div className="inner_banner_wrapper clr" id='con_head' style={{ background: 'url("'+Properties.IMAGE_PATH+'public/gallery/MAIN%20IMAGE.png")' }}>
      
    <h1>Contact </h1>

 </div>
    <section className="py-5 contact_us">
  <div className="container">
  <>
  <section className="">
    <div className="">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <div className="contact_inner">
            <div className="row">
              <div className="col-md-10">
                <div className="contact_form_inner">
                  <div className="contact_field">
                    <h3>Contact Us</h3>
                    <p>
                      Feel Free to contact us any time. We will get back to you
                      as soon as we can!.
                    </p>
                    <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                      <Form.Group controlId="formFirstName">

                        <Form.Control
                          type="text"
                          placeholder="Enter full name"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid"> Please enter full name. </Form.Control.Feedback>
                      </Form.Group>

     

                      <Form.Group controlId="formEmail">

                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid"> Please enter email. </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="formMessage">

                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Enter your message"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid"> Please enter message. </Form.Control.Feedback>
                      </Form.Group>
                      <ReCAPTCHA
                          ref={recaptchaRef}
                          size="invisible"
                          sitekey="6LfWL_4pAAAAACz-z4nvY6hBZbgQUH9nbRtwYINm"
                          //onChange={onChange}
                      />
                    {isSubmitting ? (
                        <Button className='btn-lrg submit-btn' variant="primary" type="submit" disabled>
                          Sending...
                        </Button>
                    ) : (
                      <Button className='btn-lrg submit-btn' variant="primary" type="submit">
                        Submit
                      </Button>
                    )}
                  </Form>
    
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="right_conatct_social_icon d-flex align-items-end">
                  <div className="socil_item_inner d-flex">
                    {/* <li>
                      <a href="#">
                        <i className="fab fa-facebook-square" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter" />
                      </a>
                    </li> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="contact_info_sec">
              <h4>Contact Info</h4>
              <div className="d-flex info_single align-items-center">
                <i className="fas fa-headset" />
                <span>{pageData.phone_no}</span>
              </div>
              <div className="d-flex info_single align-items-center">
                <i className="fas fa-envelope-open-text" />
                <span>{pageData.email}</span>
              </div>
              <div className="d-flex info_single align-items-center">
                <i className="fas fa-map-marked-alt" />
                <span>{pageData.address}
                
                            
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</>

  </div>
</section>
<Footer />
</>
  )
}

export default Contact;