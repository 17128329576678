import React, { useState, useEffect,createContext } from 'react';
import './App.css';
import './Responsive.css';

import Home from './Components/Home';
import Categories from './Components/Categories';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Privacy_Policy from './Components/Privacy_Policy';
// import Cookie_Policy from './Components/Cookie_Policy';
import Not_Found from './Components/Not_Found';
// import Terms_Conditions from './Components/Terms_Conditions';
import CourseDetails from './Components/CourseDetails';
import Cart from './Components/Cart';
import Contact from './Components/Contact';
import DeliveryModesPage from './Components/DeliveryModesPage';
import Accreditation from './Components/Accreditation';
import Cmspage from './Components/Cmspage';
import Login from './Components/Login';
import Service from './Components/Service';
import Checkout from './Components/Checkout';
import LandingPage from './Components/LandingPage';
import ScrollToHashElement from './Components/ScrollToHashElement';
import {GetCategory,GetCourseByCat,GetCms} from './services/Api'
import TagManager from 'react-gtm-module';
import DelayedComponent from './Components/DelayedComponent'; // Adjust the import path accordingly

const tagManagerArgs = {
  gtmId: 'GTM-KFC9M6RJ'  // Replace with your GTM ID
};
function App() {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);
  const [catName, setCatName] = useState([]); 
  const [CourseList, setCourseList] = useState([]);
  
  const fetchCatName = async () => {      
    await GetCategory()
    .then(catresponse => {
      if (catresponse.status===200) {
        // console.log("Category APP JS====>",catresponse)
       setCatName(catresponse.data); 
      }
    })
  };
  const GetCourseByCatData = async () => {
    await GetCourseByCat().then(Courseresponse => {
       if (Courseresponse.status===200) {
        setCourseList(Object.values(Courseresponse.data));         
       }
    })
 }
 
  useEffect(() => {
    fetchCatName();  
  }, []);
  useEffect(() => {
    GetCourseByCatData();
  }, []);
  const islogin = localStorage.getItem("islogin");

  return (
    <div className="App">
      <Accreditation>     
        <BrowserRouter>
          <ScrollToHashElement />
          <Routes>
            {/* <Route key="landingpage" path='/' element={<LandingPage />} /> */}
            {/* {(islogin) ? ( */}
              <>            
              <Route key="home" path="/" element={<Home categoryList={CourseList} />} />
              {catName.map((item) => (
                <Route key={item.slug} path={item.slug} element={<Categories categoryList={CourseList} CourseData={CourseList}  slug={item.slug} />} />
              ))}
              <Route key="privacy-and-cookies-policy" path='privacy-and-cookies-policy' element={<Cmspage categoryList={CourseList} slug="privacy-and-cookies-policy" />} />
              {/* <Route key="cookie-policy" path='cookie-policy' element={<Cmspage categoryList={CourseList} slug="cookie-policy" />} /> */}
              <Route key="terms-conditions" path='terms-conditions' element={<Cmspage categoryList={CourseList} slug="terms-conditions" />} />
              <Route key="business-consultation" path='business-consultation' element={<Service categoryList={CourseList} slug="service" />} />

              <Route key="online-instructor-led-training" path="online-instructor-led-training" element={<DeliveryModesPage categoryList={CourseList} slug="online-instructor-led-training" />} />
              <Route key="online-self-paced-training" path="online-self-paced-training" element={<DeliveryModesPage categoryList={CourseList} slug="online-self-paced-training" />} />
              <Route key="onsite-instructor-led-training" path="onsite-instructor-led-training" element={<DeliveryModesPage categoryList={CourseList} slug="onsite-instructor-led-training" />} />
              <Route key="classroom-training" path="classroom-training" element={<DeliveryModesPage categoryList={CourseList} slug="classroom-training" />} />
              {CourseList?.length && CourseList.map((item) => (              
                item.details.course?.length && item.details.course.map((course, courseIndex) => (
                <Route key={course.course_slug} path={`/${item.details.cat_slug}/${course.course_slug}`} element={<CourseDetails catSlug={item.details.cat_slug} courseSlug={course.course_slug} categoryList={CourseList}/>} />
                ))
              ))}
              <Route key="cart" path='/cart' element={<Cart categoryList={CourseList}  pg="cart" />} />
              <Route key="userinfo" path='/cart/userinfo' element={<Cart categoryList={CourseList} pg="userinfo" />} />
              <Route key="checkout" path='/cart/checkout' element={<Cart categoryList={CourseList} pg="checkout" />} />
              <Route key="contact" path='/contact' element={<Contact categoryList={CourseList} slug="con" />} />            
              </>
            {/* ):( */}
              {/* <Route key="home" path="/home" element={<Login />} /> */}
            {/* )} */}
            <Route key="404" path='*' delay={7000} interval={10000} element={<DelayedComponent delay={5000}>
              <Not_Found categoryList={CourseList} />
            </DelayedComponent>} />
          </Routes>
        </BrowserRouter>
      </Accreditation>
    </div>
  );
}

export default App;