import React, { useEffect, useState,useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { getCartData } from '../services/Api';
import { Link } from 'react-router-dom';
import Properties from '../properties.js';



const MiniCart = ({miniCartShow,miniCartClose}) => {
   //console.log(miniCartShow);
   const [getcart, setCartData] = useState([]);
   const cartData = JSON.parse(localStorage.getItem('cart'));
   const cartDetailsDt = JSON.parse(localStorage.getItem('cartDetails'));
   const [alertShow, setAlertShow] = useState(false);
   const [alertBtnval, setalertBtnval] = useState();

   const getCartDatafun = async () => {
    if(cartData?.length){
      await getCartData(cartData).then(cartres => {
        //console.log("here");
        if (cartres.status === 200) {
          //console.log("cartres",cartres.data);
          const cartDataWithQuantity = cartres.data.map(item => ({ ...item, quantity:  cartDetailsDt?.find(dtlsitem => dtlsitem.course_name === item.course_name)?.quantity || 1 }));
          //console.log("cartDataWithQuantity===>",cartDataWithQuantity);
          cartDataWithQuantity.sort(customSort);
          setCartData(cartDataWithQuantity);
        }
      })
    }
  };
  function customSort(a, b) {
      return cartData.indexOf(a.course_venue_id) - cartData.indexOf(b.course_venue_id);
  }

  useEffect(() => {
    getCartDatafun();
  }, [cartData?.length]);
  const currencyPrice = (pricedt) => {
    const currency = localStorage.getItem('currency');
    //console.log("pricedt===>",pricedt)
    switch (currency) {
        case 'pound':
            return "£ "+(parseFloat(pricedt.pound_price * pricedt.quantity)).toLocaleString();
        case 'doller':
          return "$ "+(parseFloat(pricedt.inr_price * pricedt.quantity)).toLocaleString();
        default:
          return "€ "+(parseFloat(pricedt.euro_price * pricedt.quantity)).toLocaleString();
    }
  };
  const calculateTotalPrice = () => {
    const currency = localStorage.getItem('currency');
    let curr;
    if (currency === "pound") {
      curr = "£ ";
    } else if (currency === "dollar") {
      curr = "$ ";
    } else {
      curr = "€ ";
    }
    return curr + getcart.reduce((total, item) => {
      let price;
      if (currency === "pound") {
        price = item.pound_price;
      } else if (currency === "dollar") {
        price = item.inr_price;
      } else {
        price = item.euro_price;
      }
      return total + (parseFloat(price) * parseInt(item.quantity));
    }, 0);
  };
  const cartRemove = (event) => {
    let targetval = event.target.value;
    //console.log("getCart",getcart);
    const newCart = [...getcart];
    newCart.splice(targetval, 1);
    setCartData(newCart);
    localStorage.setItem('cartDetails', JSON.stringify(newCart));
    const cartIds = JSON.parse(localStorage.getItem('cart'));
    cartIds.splice(targetval, 1);
    localStorage.setItem('cart', JSON.stringify(cartIds)); // Store updated cart in local storage
    setAlertShow(false);
  }
  const remvPop = useCallback((val) => {
    setalertBtnval(val)
    setAlertShow(true); // Set alertShow state to true
  }, []);
    return (
        <>        
            <Modal
        show={miniCartShow}
        backdrop="static"
        keyboard={false}
        animation={false}
        className='miniCartModal'
      >
                <Button variant="secondarys"  onClick={miniCartClose}>
                <i class="fa-solid fa-xmark"></i>
          </Button>
        <Modal.Header>
          <Modal.Title>Your Cart</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {getcart?.length > 0 && getcart.map((item, index) => (
          <>
          <div className='mini_heading'>
            {item.course_name??''}
            <span><Link onClick={() => remvPop(index)}><i class="fa-regular fa-trash-can"></i></Link></span>
          </div>
          <table class="table" key={index}>

            <tbody>
              <tr>

                <td>Date</td>
                <td><b>{item.course_name??''}</b></td>
                <td className='rts'><strong>{currencyPrice(item)}</strong></td>
              </tr>
              <tr>

                <td>Duration </td>
                <td><b>{item.duration??''} Days</b></td>
                <td className='rts'>(Inc VAT)</td>
              </tr>
              <tr>

                <td>Location</td>
                <td colspan="2"><b>{item.venue_name??''}</b></td>
              </tr>
            </tbody>
          </table>
          <div className='blutxt'>
            <Link to={Properties.BASE_URL+item.cat_slug + "/" + item.course_slug}> Change Course Details <i class="fa-solid fa-arrow-right-long"></i></Link>
          </div>
          </>
        ))}

        <div className='total'>
          <h6>Total</h6>
          <div className='toprice'>
          {calculateTotalPrice().toLocaleString()}
          <p> (Inc VAT)</p>
          </div>

        </div>
        <div class="check-btn"><Link to="/cart">Checkout</Link></div>
  
          
      </Modal.Body>        
      </Modal>  
      <Modal
        show={alertShow}
        backdrop="static"
        keyboard={false}
        animation={false}
      >
        <Modal.Header>
          <Modal.Title>Are you sure you want to remove this course?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="secondary" value={alertBtnval} onClick={cartRemove} >
            Yes
          </Button>
          <Button variant="secondary" onClick={()=>{setAlertShow(false)}}>
            No
          </Button>
        </Modal.Body>        
      </Modal>      
        </>
    );

}
export default MiniCart;