import React from 'react'
import Header from './Header';
import Footer from './Footer';
import Properties from '../properties.js';
const Not_Found = (props) => {
  const islogin = localStorage.getItem("islogin");
  // if(!islogin){
  //   window.location.href="/home";
  // }
  return (<>
  {props.categoryList.length>0 && <Header clsName={"inner-header"}  categoryList={props.categoryList} />}
  
    <section className="catabox py-5">
      <div className="container">
        <div className='nt'>
        <img src={Properties.IMAGE_PATH+`public/gallery/404_Page.svg`} alt="" />
        </div>
      </div>
    </section>
<Footer />
</>
  )
}

export default Not_Found